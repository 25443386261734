<template>
  <div>
    Welcome Fun-Hub User!
  </div>
</template>

<script>
import router from '@/router';
import { mapActions } from 'vuex';

export default {
  name: 'funhub',

  created() {
    const destination = this.$route.query?.dest;
    let redirect = { name: 'chooseprogram' };

    // do we have the destination query param?
    if (destination != null) {
      const parts = destination.split('.');

      // Only one item (program)
      if (parts.length === 1) {
        const programId = `fun${parts[0]}`.toLowerCase();
        this.setCurrentProgram({ program: programId });
        if (programId === 'fun2' || programId === 'fun3') {
          redirect = { name: 'chooseunit', params: { programId } };
        } else {
          redirect = { name: 'chooseactivity', params: { programId, unitId: '0', weekId: '0', dayId: '0' } };
        }
      } else if (parts.length >= 2) {
        // Are there at least 2 parts? (program.unit)
        // is it K or 1, ignore the rest of the parts
        const programId = `fun${parts[0]}`.toLowerCase();
        if (programId === 'funk' || programId === 'fun1') {
          this.setCurrentProgram({ program: programId });
          redirect = { name: 'chooseactivity', params: { programId, unitId: '0', weekId: '0', dayId: '0' } };
        } else {
          const unitId = parts[1].toString();
          this.setCurrentProgram({ program: programId });
          redirect = { name: 'chooseweek', params: { programId, unitId } };
        }
      }
    }
    router.push(redirect);
  },

  methods: {
    ...mapActions('appState', ['setCurrentProgram']),

  },
};

</script>
