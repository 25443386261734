var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"positioner"}},[_c('ActivityHeader',{attrs:{"activityType":"Reference Posters","activityName":"Syllable Types","back-button-title":"Posters","show-nav-buttons":false,"back-button-route":"chooseposters"}}),_c('div',{staticStyle:{"position":"relative","flex":"1 0 auto"}},[_c('div',{directives:[{name:"images-loaded",rawName:"v-images-loaded:on",value:(_vm.imageProgress()),expression:"imageProgress()",arg:"on"}],attrs:{"id":"card-positioner","data-automationId":"reference_posters_syllable_types_with_img"}},[(_vm.programId === 'fun2')?_c('div',{staticClass:"syllable-types-grid",attrs:{"id":"cards-container"}},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7)]):_vm._e(),(_vm.programId === 'fun3')?_c('div',{staticClass:"syllable-types-grid",attrs:{"id":"cards-container"}},[_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12),_vm._m(13),_vm._m(14),_vm._m(15),_vm._m(16),_vm._m(17),_vm._m(18),_vm._m(19),_vm._m(20)]):_vm._e()])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":"/images/Fun2/spelling-options/1.svg","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":"/images/Fun2/spelling-options/2.svg","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":"/images/Fun2/spelling-options/3.svg","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":"/images/Fun2/spelling-options/4.svg","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":"/images/Fun2/spelling-options/5.svg","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":"/images/Fun2/spelling-options/6.svg","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":"/images/Fun2/spelling-options/7.svg","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":"/images/Fun2/spelling-options/8.svg","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":"/images/Fun3/spelling-options/1.svg","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":"/images/Fun3/spelling-options/2.svg","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":"/images/Fun3/spelling-options/3.svg","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":"/images/Fun3/spelling-options/4.svg","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":"/images/Fun3/spelling-options/5.svg","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":"/images/Fun3/spelling-options/6.svg","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":"/images/Fun3/spelling-options/7.svg","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":"/images/Fun3/spelling-options/8.svg","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":"/images/Fun3/spelling-options/9.svg","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":"/images/Fun3/spelling-options/10.svg","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":"/images/Fun3/spelling-options/11.svg","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":"/images/Fun3/spelling-options/12.svg","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":"/images/Fun3/spelling-options/13.svg","alt":""}})])
}]

export { render, staticRenderFns }