<template>
  <div id="positioner" class="drillsounds">
    <ActivityHeader activity-type="<span class='twoline-title'><span>Drill Sounds</span><br><span>/Warm-Up</span></span>"
     activity-name="Sound Alike"
     :back-button-title="backButtonTitle"
     back-button-route="choosedrillsounds" />

    <div id="carousel-wrapper" class="soundalike-carousel" v-if="!showConfig">
      <div id="help-text-wrapper">
        <span id="help-text">Select sound alike flashcards below to begin</span>
      </div>
      <carousel-3d ref="carousel" :controls-visible="true" :count="wordsInCarousel.length" :space="100" :perspective="0" :clickable="false">
        <slide v-for="(word, i) in wordsInCarousel" :index="i" :key="i">
          <div class="silent-letter-card box" @click="showPairs(word.idx)">
            <div class="word" data-automationId="sound_alike_selected">{{ word.word }}</div>
            <span class="label">
              Fundations
              <span class="tw-fun-level">Level 3</span>
            </span>
            <span class="counter">{{ i + 1 }} of {{ wordsInCarousel.length }}</span>
          </div>
        </slide>
      </carousel-3d>
      <span class="explainer-label" v-bind:class="{ showExplainer: showExplainer }"><span>Tap a sound alike card to view its pair</span></span>
    </div>
    <div class="unit-buttons" v-if="!showConfig">
      <button class="button plain-button hide-words" :class="{showWords: !unitWordsVisible}" @click="toggleUnitWordVisibility()">{{toggleTitle}}</button>
      <button class="button button-sm clear-words" type="button" @click="clearAllWords()" data-automationId="sound_alike_clear_words_button">Clear Words</button>
    </div>
    <div id="controls" v-if="!showConfig && unitWordsVisible">
      <button class="unit-nav-button" style="margin-right: auto;" v-if="wordsInUnit.length > 4" @click="prevUnit()">&lt; Prev Unit</button>
      <div class="unit" v-for="units in wordsInUnit.slice(unitPage * 4, (unitPage * 4) + 4)" :key="units.unit">
        <div class="unit-label"><label><input type="checkbox" :value="units.unit" v-model="unitsSelected" @change="changeUnit($event, units.unit)"> {{ unitLabel(units.unit) }}</label></div>
        <div class="words">
          <div class="word" v-for="(wordPairs) in units.wordPairs" :key="wordPairs.idx" data-automationId="sound_alike_checkbox">
            <label><input type="checkbox" :value="{words: wordPairs.words, idx: wordPairs.idx, unit: units.unit }" v-model="wordList" @click="changeWord()"> {{ wordPairs.words.join('/') }}</label>
          </div>
        </div>
      </div>
      <button class="unit-nav-button" style="margin-left: auto;" v-if="wordsInUnit.length > 4" @click="nextUnit()">&gt; Next Unit</button>
    </div>

    <sound-alike-pairs
      :program-id="programId"
      :word-pair="selectedWordPair"
      v-if="showConfig"
      @closed="hidePairs"
    ></sound-alike-pairs>
    <PrevNextButtons activity="SA"></PrevNextButtons>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { Carousel3d, Slide } from 'vue-carousel-3d';
import SoundAlikePairs from '@/components/SoundAlikePairs.vue';
import ActivityHeader from '@/components/ActivityHeader.vue';
import PrevNextButtons from '@/components/PrevNextButtons.vue';

export default {
  name: 'SoundAlike',
  components: { ActivityHeader, PrevNextButtons, Carousel3d, Slide, SoundAlikePairs },
  data() {
    return {
      wordPairs: [],
      wordList: [],
      wordsInUnit: [],
      showConfig: false,
      showExplainer: false,
      explainerHasBeenShown: false,
      selectedWordPair: [],
      backButtonTitle: '',
      unitsSelected: [],
      unitWordsVisible: true,
      totalUnitPages: 0,
      unitPage: 0,
      toggleTitle: 'Hide Units',
    };
  },
  props: {
    programId: { type: String },
    unitId: { type: String },
    weekId: { type: String },
    dayId: { type: String },
  },
  created() {
    this.getDataForDisplay();
  },
  mounted() {
    this.backButtonTitle = this.weekId === '0' ? 'Back' : 'Drill Sounds';
  },
  computed: {
    ...mapState('soundalike', ['soundAlikeWords']),
    ...mapGetters('dataStore', ['weekNumber', 'unitAndWeek']),
    wordsInCarousel() {
      return this.wordList.flatMap((w) => w.words.map((wd) => ({ word: wd, idx: w.idx })));
    },
  },
  methods: {
    toggleTapHelpLabel() {
      if (!this.explainerHasBeenShown) {
        this.showExplainer = true;
        setTimeout(() => {
          this.hideExplainer();
        }, 4000);
        this.explainerHasBeenShown = true;
      }
    },
    toggleUnitWordVisibility() {
      this.unitWordsVisible = !this.unitWordsVisible;
      if (this.unitWordsVisible === true) {
        this.toggleTitle = 'Hide Units';
      } else {
        this.toggleTitle = 'Show Units';
      }
    },
    unitLabel(unit) {
      return unit === 'Bonus' ? 'Bonus Unit' : `Unit ${unit}`;
    },
    hideExplainer() {
      this.showExplainer = false;
    },
    showPairs(pairId) {
      this.showConfig = true;
      this.selectedWordPair = this.wordList.find((w) => w.idx === pairId).words;
    },
    hidePairs() {
      this.showConfig = false;
      this.hideExplainer();
    },
    nextUnit() {
      this.unitPage += 1;
      if (this.unitPage > this.totalUnitPages - 1) {
        this.unitPage = 0;
      }
    },
    prevUnit() {
      this.unitPage -= 1;
      if (this.unitPage < 0) {
        this.unitPage = this.totalUnitPages;
      }
    },
    changeWord() {
      this.$refs.carousel.goSlide(this.$refs.carousel.currentIndex);
      this.toggleTapHelpLabel();
    },
    changeUnit(evt, unit) {
      const words = this.wordsInUnit.filter((u) => u.unit === unit);
      const toAdd = words.flatMap((word) => word.wordPairs.map((wp) => ({ words: wp.words, idx: wp.idx, unit })));
      this.wordList = this.wordList.filter((w) => w.unit !== unit);

      if (evt.target.checked) {
        this.wordList = this.wordList.concat(toAdd);
      }
      this.$refs.carousel.goSlide(this.$refs.carousel.currentIndex);
      this.toggleTapHelpLabel();
    },
    clearAllWords() {
      this.wordList = [];
      this.unitsSelected = [];
    },
    getDataForDisplay() {
      const soundAlikeForProgram = this.soundAlikeWords.find((c) => c.program === this.programId);
      let unitNumber = 0;
      if (this.unitId === 'Bonus') {
        unitNumber = 7;
      } else {
        const unitInt = parseInt(this.unitId, 10);
        unitNumber = unitInt >= 7 ? unitInt + 1 : unitInt;
      }
      this.wordsInUnit = soundAlikeForProgram.data.filter((f) => f.unitNumber <= unitNumber);
      this.totalUnitPages = Math.ceil(this.wordsInUnit.length / 4);
    },
  },
};
</script>

<style lang="scss" scoped>
  .soundalike-carousel{
    display: flex;
    flex-direction: column;
    #help-text-wrapper {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      display: flex;
      align-content: center;
      justify-items: center;
      #help-text{
        flex: 1 0 auto;
        text-align: center;
        @extend %headings-shared;
        margin: auto;
        font-size: 2vh;
        color: gray;
      }
    }
    .explainer-label{
      background-image: url('/images/pointer.svg');
      background-size: 25px;
      background-position: center left;
      background-repeat: no-repeat;
      padding: 1rem 0 1rem 3rem;
      font-size: 2rem;
      position: absolute;
      top: 100%;
      align-self: center;
      transition: opacity 1s ease-in-out;
      opacity: 0;
      span{
        background-image: radial-gradient(circle, #fffca7, white);
      }
      &.showExplainer{
        opacity: 1;
      }
    }
  }

  #controls {
    display: flex;
    justify-content: center;
    font-family: wManuscript;
    margin: 0;
    width: 100%;
    box-sizing: border-box;
    .unit-nav-button{
      flex: 0 0 8%;
    }
    .unit {
      flex: 0 0 21%;
      background: whitesmoke;
      margin: 0 .5%;
      box-sizing: border-box;
      padding: 10px;

      .unit-label {
        @extend %headings-shared;
        font-size: 2.2rem;
        margin-bottom: 1.5rem;
      }
      .words{
        display: flex;
        flex-wrap: wrap;
        .word {
          font-size: 1.4rem;
          cursor: pointer;
          flex: 1 0 50%;
          @media(max-width: 1200px), (max-height: 800px){
            flex: 1 0 33%;
          }

          label{
            padding-bottom: 8px;
            display: block;
            margin: 1px 0;
          }
        }
      }
      @media(max-width: 1000px), (max-height: 700px){
        .unit-label{
          font-size: 1.5rem;
          margin-bottom: .5rem;
        }
        .words {
          .word {
            flex: 1 0 auto;
          }
        }
      }
      &.shown{
        display: block;
      }
    }
  }
  .unit-buttons{
    display: flex;
    position: relative;
    button {
      &.hide-words{
        align-self: center;
        justify-self: center;
        margin: auto;
        position: relative;
        padding-right: 25px;
        &:before {
          content: '';
          background-image: url(/images/Fun1/illustrations/up.svg);
          background-repeat: no-repeat;
          background-size: contain;
          padding-right: 20px;
          position: absolute;
          right: 0;
          height: 29px;
          transform: rotateZ(20deg) scaleY(-1);
          top: 5px;

        }
        &.showWords {
          &:before{
            transform: rotateZ(-10deg);
          }
        }
      }
      &.clear-words{
        position: absolute;
        right: 0;
      }
    }
  }
</style>
