<template>
  <div id="positioner" :class="activityType.replace(/ /g, '-')">
    <ActivityHeader
      :activity-type="activityType"
      :back-button-title="backButtonTitle"
      header-is-compressed="true"
      back-button-route="chooseactivity"
    />
    <div id="drop-rows">
      <div id="phantom-drop-rows">
        <div id="pdr-1" class="row-1"></div>
        <div id="pdr-2" class="row-2"></div>
      </div>
      <div id="drop-rows-help-text" style="display: none;" v-show="false">
        <span class="row-help-text row-1" id="rht-1">&raquo; Build Area: Drop Cards &amp; Frames Here to Build Words</span>
        <span class="row-help-text row-2" id="rht-2">&raquo; Staging Area: Drop Cards Here</span>
      </div>
      <div
        id="row-1"
        class="drop-row rht-1"
        dropzone
        @drop.self="handleDroppedItemToRow($event, 0, false)"
        @dragenter="dragEnter"
        @dragend="dragEnd"
        @dragleave.prevent.stop="dragLeave"
        @dragover.prevent
        @click.self="removeSpellingOptionsPopunder()"
      >
        <card-frame
          class="activeFrame"
          v-for="(frameData, $index) in activeFrames"
          :key="`${frameData.frameType}-${frameData.frameIndex}`"
          :class="frameData.frameType"
          :frameType="frameData.frameType"
          :frameIndex="frameData.frameIndex"
          :location="frameData.location"
          :initialCards="frameData.initialCards"
          :data-index="$index"
          :style="{ left: frameData.position }"
          @frameClose="cards => frameClose(frameData, $index, cards)"
          @cardDroppedOnFrame="cardDroppedOnFrame"
          @cardDropped="cardMovedToFrame"
        >
          <button class="remove-frame">X</button>
        </card-frame>

        <!-- cards on word line 1 -->
        <div
          class="sc move"
          v-for="(cardData, $index) in wordLine[0]"
          :key="$index"
          :class="[{ 'has-popunder': $index === spellingOptionsCardIndex }, cardData.card.color]"
          :style="{ left: cardData.position }"
          :draggable="cardData.card.cardId > 0 && $index !== spellingOptionsCardIndex"
          :data-cardId="cardData.card.cardId"
          @dragstart="dragCardFromRow($event, cardData.card, $index, 0)"
          @drop="handleDroppedItemToRow($event, 0, true)"
          @dragend="onCardDragEnd($event, $index, 0)"
          @touchstart="startCardTouchDrag"
          @touchmove="onCardTouchDrag"
          @touchend="onTouchEnd"
          @click="appendSpellingOptionsPopunder($event, $index, cardData.card.color)"
        >
          <div class="letter-holder">
            <span class="sc-letter" v-if="cardData.card.cardId < 900 && !cardData.card.isBlank" v-html="cardData.card.label"></span>
            <span class="sc-letter" v-else>&nbsp;</span>
          </div>
          <div @dragenter.self="hideme" id="tap-explainer" class="explainer-label" v-if="showSopLabelsToday() && !cardData.card.suffix">
            <img src="/images/pointer.svg" alt="pointer" />
            <span>Tap any card to add <br />Spelling Options</span>
          </div>
        </div>

        <SpellingOption
          @removeSpellingOptionsPopunder="removeSpellingOptionsPopunder"
          :class="spellingOptionState"
          :current-day="this.currentDay"
          :current-program="this.currentProgram"
          :style="{ left: 'calc( ' + SOposition + ' - .75%)' }"
          :clearCards="clearSpellingOptionCards"
        ></SpellingOption>
      </div>

      <div
        id="row-2"
        class="drop-row rht-2"
        dropzone
        @drop.self="handleDroppedItemToRow($event, 1, false)"
        @dragenter="dragEnterSecondLine"
        @dragend="dragEnd"
        @dragleave.prevent.stop="dragLeave"
        @dragover.prevent
        @click.self="removeSpellingOptionsPopunder()"
      >
        <!-- cards on word line 2 -->
        <div
          class="sc move"
          v-for="(cardData, $index) in wordLine[1]"
          :key="$index"
          :class="cardData.card.color"
          :style="{ left: cardData.position }"
          draggable
          @dragstart.self="dragCardFromRow($event, cardData.card, $index, 1)"
          @drop="handleDroppedItemToRow($event, 1, true)"
          @dragend="onCardDragEnd($event, $index, 1)"
          :data-cardId="cardData.card.cardId"
          @touchstart="startCardTouchDrag"
          @touchmove="onCardTouchDrag"
          @touchend="onTouchEnd"
        >
          <div class="letter-holder" @dragover.prevent @dragenter.prevent>
            <span class="sc-letter" v-if="cardData.card.cardId < 900" v-html="cardData.card.label"></span>
            <span class="sc-letter" v-else>&nbsp;</span>
          </div>
        </div>
      </div>
    </div>
    <div style="position: relative; flex: 1 0 auto; margin-top: 2%;">
      <div id="card-positioner">
        <div id="cards-container" class="standard-card-container">
          <LetterCard
            v-for="card in cardsToShow"
            :key="card.cardId"
            :card="card"
            :cardClass="card.color + ' sc'"
            @cardDropped="cardDropped"
          ></LetterCard>
        </div>
      </div>

      <div class="button-container">
        <div id="frames-container" v-if="programId !== 'funk'">
          <div id="sylframes-module-wrapper">
            <div class="frames-helper-text" v-if="syllableFramePool.length > 0">
              <span class="frames-label">Syllable Frames</span>
            </div>
            <div id="sylFrames">
              <card-frame
                v-for="(frame, $index) in syllableFramePool"
                :key="$index"
                :frameType="frame.frameType"
                :frameIndex="frame.frameIndex"
                :location="frame.location"
                ref="frame"
              ></card-frame>
            </div>
            <div class="frames-helper-text" v-if="syllableFramePool.length > 0">
              <span class="frames-guide">Drag above to add</span>
            </div>
          </div>

          <!-- NOTICE: intentionally left commented for future use -->
          <div id="sufframes-module-wrapper" v-if="showSuffixFrames()">
            <div class="frames-helper-text" v-if="suffixFramePool.length > 0">
              <span class="frames-label">Sentence Frames</span>
            </div>
            <div id="sufFrames">
              <card-frame
                v-for="(frame, $index) in suffixFramePool"
                :key="$index"
                :frameType="frame.frameType"
                :frameIndex="frame.frameIndex"
                :location="frame.location"
                ref="frame"
              ></card-frame>
              <div class="frames-helper-text" v-if="suffixFramePool.length > 0">
                <span class="frames-guide">Drag above to add</span>
              </div>
            </div>
          </div>
        </div>
        <template v-if="dayId !== '0'">
          <button
            type="button"
            @click="showModal"
            :disabled="wordBankWords.length == 0"
            v-bind:class="{ 'no-words-today': wordBankWords.length == 0 }"
            class="plain-button word-bank-toggle"
          >
            <span>Word Bank</span>
          </button>
          <span class="sdp" v-if="wordBankWords.length == 0">See daily plan in teacher's manual</span>
        </template>

        <div id="clear-buttons">
          <button class="button-sm plain-button clear-cards-toggle" type="button" @click="clearWords">
            <div class="button-image-holder"></div>
            <span>Clear Cards</span>
          </button>
          <template v-if="programId !== 'funk'">
            <button class="button-sm plain-button clear-frames-toggle" type="button" @click="clearFrames">
              <div class="button-image-holder"></div>
              <span>Clear Frames</span>
            </button>
            <button class="button-sm plain-button clear-all-toggle" type="button" @click="clearAll">
              <div class="button-image-holder"></div>
              <span>Clear All</span>
            </button>
          </template>
        </div>
        <a id="online-dictionary" href="http://dictionary.com" rel="noopener noreferrer" target="_blank">Online Dictionary</a>
      </div>
    </div>
    <PrevNextButtons v-if="!isFunkFun1" activity="INC"></PrevNextButtons>
    <modal-dialog v-show="isModalVisible" @close="closeModal" :class="{ 'has-selected-word': selectedWord != '' }">
      <template v-slot:header>
        <h2>Word Bank</h2>
        <span><em>Select a word to automatically build it above</em></span>
      </template>
      <template v-slot:body>
        <div class="wordbank-grid">
          <div
            v-for="(word, $index) in wordBankWords"
            :key="`${word.word}${$index}`"
            class="wordbank-item"
            :class="{ 'selected-word': selectedWord == word.word }"
            @click="selectWord(word)"
          >
            <span v-html="word.word"></span>
          </div>
        </div>
      </template>
    </modal-dialog>
    <modal-dialog v-show="isAltModalVisible" @close="closeModal" class="altPostersModal">
      <template v-slot:body>
        <p><strong>Introduce New Concepts</strong> will focus on <em>sounds</em> today. Use the appropriate poster in the <strong>Reference Posters</strong> section of Interactivities.</p>
        <router-link
          :to="{ name: 'referenceposters', params: { programId: programId } }"
          class="button"
          id="reference-posters-shortcut"
        >
          <img src="/images/poster-tiles/posters.svg" alt="reference posters">
          <span>Reference Posters</span>
        </router-link>
      </template>
    </modal-dialog>
    <span class="resizeCautionMessage slideOutMessage" v-bind:class="{ shown: resizeCautionMessage }">
      Careful! If you resize your screen, your built words may be affected.
    </span>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import router from '@/router';
import ActivityHeader from '@/components/ActivityHeader.vue';
import ModalDialog from '@/components/ModalDialog.vue';
import LetterCard from '@/components/LetterCard.vue';
import SpellingOption from '@/components/SpellingOption.vue';
import CardFrame from '@/components/CardFrame.vue';
import cardSizer from '@/mixins/cardsizer';
import PrevNextButtons from '@/components/PrevNextButtons.vue';
import dragHelper from '@/helpers/checkNativeDrag';

export default {
  name: 'BuildWords',
  components: { ActivityHeader, ModalDialog, LetterCard, CardFrame, SpellingOption, PrevNextButtons },
  mixins: [cardSizer],
  data() {
    const isModalVisible = false;
    const isAltModalVisible = false;
    const spellingOptionState = 'hidden';
    const cardsToShow = [];
    const wordBankWords = [];
    const wordLine1 = [];
    const wordLine2 = [];
    const wordLine = { 0: [], 1: [] };
    const activeFrames = [];
    const syllableFramePool = [];
    const suffixFramePool = [];
    const SOposition = 0;
    const cardDragging = false;
    const spellingOptionsCardIndex = -1;
    const clearSpellingOptionCards = false;
    const showSpellingInfo = true;
    const wordBankFrameIndex = 0;
    const selectedWord = '';
    const SChasBeenDropped = false;
    const initialResize = 0;
    const currentDay = 0;
    const currentProgram = '';
    const backButtonTitle = '';
    const activityType = '';
    const resizeCautionMessage = false;
    const resizeCautionMessageAlreadyShown = false;

    return {
      SOposition,
      isModalVisible,
      isAltModalVisible,
      cardsToShow,
      wordBankWords,
      wordLine1,
      wordLine2,
      wordLine,
      activeFrames,
      spellingOptionState,
      suffixFramePool,
      syllableFramePool,
      cardDragging,
      spellingOptionsCardIndex,
      clearSpellingOptionCards,
      showSpellingInfo,
      wordBankFrameIndex,
      selectedWord,
      SChasBeenDropped,
      initialResize,
      currentDay,
      currentProgram,
      backButtonTitle,
      activityType,
      resizeCautionMessage,
      resizeCautionMessageAlreadyShown,

      touchTimeout: null,
      clone: null,
      dragEvent: null,
      dropped: false,
    };
  },
  props: {
    programId: { type: String },
    unitId: { type: String },
    weekId: { type: String },
    dayId: { type: String },
  },
  computed: {
    ...mapState('buildwords', ['programCardData']),
    ...mapState('wordBank', ['wordLists']),
    ...mapState('appState', ['displayedSpellingOptions']),
    ...mapGetters('dataStore', ['weekNumber', 'dayNumber']),

    isFunkFun1() {
      return this.programId === 'funk' || this.programId === 'fun1';
    },
  },
  mounted() {
    this.getCardsForDisplay();
    this.getWordsForWordbank();

    this.initializeFramePools();
    window.addEventListener('resize', this.resizeMethods);
    // leave for now
    // window.addEventListener('resize', this.showResizeCautionMessage);
    this.backButtonTitle = this.weekId === '0' ? 'Back' : 'Activities';
    if (this.isFunkFun1) {
      this.activityType = 'Make Words For Decoding';
    } else if (this.weekId === '0') {
      this.activityType = 'Build Words';
    } else {
      this.activityType = 'Introduce New Concepts';
    }

    // add touch enter listener to rows
    const row1 = document.getElementById('row-1');
    const row2 = document.getElementById('row-2');
    row1.ontouchenter = this.dragEnter;
    row2.ontouchenter = this.dragEnterSecondLine;
  },
  updated() {
    this.checkRowHeights();
    this.initialResizeTest();
    this.resizeMethods();
  },
  created() {
    if (!(this.programId && this.weekId && this.unitId)) {
      console.error('program, unit and/or week undefined!');
      router.push({ name: 'home' });
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.resizeMethods);
    // leave for now
    // window.removeEventListener('resize', this.showResizeCautionMessage);
  },
  methods: {
    ...mapActions('buildwords', ['setDisplayPosition', 'setOverridePosition', 'setNewPosition']),
    ...mapActions('appState', ['updateSpellingOptionsDisplay']),

    checkRowHeights() {
      const rowAmt = window
        .getComputedStyle(document.getElementById('cards-container'))
        .getPropertyValue('grid-template-rows')
        .split(' ').length;
      if (rowAmt < 8) {
        document.getElementById('positioner').classList.add('increased-row-height');
      }
      if (rowAmt > 10) {
        document.getElementById('positioner').classList.add('decreased-row-height');
      }
    },
    showSuffixFrames() {
      return false;
    },

    hideme(e) {
      e.preventDefault();
      e.toElement.style.display = 'none';
    },
    initializeFramePools() {
      this.syllableFramePool = [];
      this.suffixFramePool = [];
      for (let index = 0; index < 3; index += 1) {
        this.syllableFramePool.push({ frameType: 'sylFrame', frameIndex: index, location: 'stack' });
        this.suffixFramePool.push({ frameType: 'sufFrame', frameIndex: index, location: 'stack' });
      }
    },
    initialResizeTest() {
      if (this.initialResize === 0) {
        this.resizeMethods();
      }
      this.initialResize = 1;
    },
    // frame events
    frameClose(frame, index, cards) {
      const framePosition = parseFloat(frame.position);

      // if the shift key was held down, don't copy them to the word lines.
      if (!cards.shiftKey) {
        cards.cards.forEach((item) => {
          if (item.cardId > 0) {
            const cardPosition = parseFloat(this.convertToPercentageInRow(item.offset));
            const pos = framePosition + cardPosition;
            this.wordLine[0].push({ card: item.card, position: `${pos}%` });
          }
        });
      }
      this.activeFrames.splice(index, 1);

      if (frame.frameType === 'sylFrame') {
        this.syllableFramePool.push({ frameType: frame.frameType, frameIndex: frame.frameIndex, location: 'stack' });
      } else {
        this.suffixFramePool.push({ frameType: frame.frameType, frameIndex: frame.frameIndex, location: 'stack' });
      }
    },

    cardDroppedOnFrame() {
      this.cardDragging = false;
    },

    runTouchTimeout() {
      this.touchTimeout = setTimeout(() => {
        this.stopTouchTimeout();
      }, 250);
    },

    stopTouchTimeout() {
      clearTimeout(this.touchTimeout);
      this.touchTimeout = null;
    },

    // drag and drop events
    startCardTouchDrag(e) {
      if (!dragHelper.isNativeDragSupported()) return;

      // stop the touch from being mapped to a click
      if (e.cancelable) e.preventDefault();

      // make sure it's not a click
      this.runTouchTimeout();
      this.dropped = false;

      // if there is another clone, don't do it
      const existingClone = document.querySelector('.ghost-element');
      if (existingClone) return;

      // grab the whole card
      let element = e.srcElement;
      while (!element.hasAttribute('data-cardId')) {
        element = element.parentElement;
      }

      // stop if the element isn't draggable
      if (element.getAttribute('draggable') === 'false') return;

      // clone the element and add it to the DOM
      const clone = element.cloneNode(true);
      const crtRawHeight = document.getElementsByClassName('drop-row')[0].offsetHeight - 17;
      clone.classList.add('ghost-element');
      clone.style.opacity = '0.8';
      clone.style.pointerEvents = 'none';
      clone.style.position = 'absolute';

      const elementTop = element.getBoundingClientRect().top;
      const elementLeft = element.getBoundingClientRect().left;
      clone.style.top = `${elementTop}px`;
      clone.style.left = `${elementLeft}px`;
      clone.style.height = `${crtRawHeight}px`;
      clone.style.width = `${crtRawHeight * (element.offsetWidth / element.offsetHeight)}px`;

      const { clientX, clientY } = e.touches[0];
      const cursorXoffset = clientX - elementLeft;
      const cursorYoffset = clientY - elementTop;

      clone.setAttribute('data-offsetX', cursorXoffset);
      clone.setAttribute('data-offsetY', cursorYoffset);
      this.clone = clone;

      document.body.appendChild(clone);
    },

    onCardTouchDrag(e) {
      if (!dragHelper.isNativeDragSupported()) return;

      // stop a timeout if it's going
      this.stopTouchTimeout();
      this.dropped = false;

      // grab the original element
      let element = e.target;
      while (!element.hasAttribute('data-cardId')) {
        element = element.parentElement;
      }

      // if there is a clone, move it around
      // and create a drag event to match
      if (this.clone) {
        const { clientX, clientY } = e.touches[0];
        const cursorXoffset = clientX - +this.clone.getAttribute('data-offsetX');
        const cursorYoffset = clientY - +this.clone.getAttribute('data-offsetY');
        this.clone.style.left = `${cursorXoffset}px`;
        this.clone.style.top = `${cursorYoffset}px`;

        // create a drag event
        this.dragEvent = new DragEvent('dragstart', {
          clientX,
          clientY,
          dataTransfer: new DataTransfer(),
        });

        // set the item type
        this.dragEvent.dataTransfer.setData('itemType', 'card');
        this.dragEvent.dataTransfer.effectAllowed = 'copy';

        // trigger the drag
        element.dispatchEvent(this.dragEvent);

        // get any elements the current one is over
        const overElement = document.elementFromPoint(clientX, clientY);
        if (!overElement.hasAttribute('dropzone')) return;

        if (overElement.ontouchenter) {
          // trigger a drag over event
          const effect = overElement.ontouchenter(this.dragEvent);
          this.dragEvent.dataTransfer.effectAllowed = effect;

          const evt = new DragEvent('dragenter', {
            clientX,
            clientY,
            dataTransfer: this.dragEvent.dataTransfer,
            target: overElement,
          });
          overElement.dispatchEvent(evt);
        }
      }
    },

    onTouchEnd(e) {
      if (!dragHelper.isNativeDragSupported()) return;

      // stop the timeout
      if (this.touchTimeout) {
        this.stopTouchTimeout();

        // grab the whole card
        let element = e.srcElement;
        while (!element.hasAttribute('data-cardId')) {
          element = element.parentElement;
        }

        // find the card index
        const id = +element.getAttribute('data-cardId');
        const index = this.wordLine[0].findIndex((data) => data.card?.cardId === id);
        if (index !== -1) {
          const cardData = this.wordLine[0][index];
          this.appendSpellingOptionsPopunder(e, index, cardData.card.color);
        }

        if (this.clone) {
          document.body.removeChild(this.clone);
          this.clone = null;
          this.dragEvent = null;
        }

        return;
      }

      if (this.clone) {
        // grab the current ghost element coords
        const { clientX, clientY } = e.changedTouches[0];
        const offsetX = clientX - this.clone.getBoundingClientRect().left;

        // find any elements that the clone is over
        const elements = document.elementsFromPoint(clientX, clientY);
        const element = elements.find((el) => el.hasAttribute('dropzone'));
        if (element) {
          // create a drop event
          const dropEvent = new DragEvent('drop', {
            clientX,
            clientY,
            dataTransfer: this.dragEvent?.dataTransfer || new DataTransfer(),
          });
          dropEvent.dataTransfer.setData('itemType', 'card');
          dropEvent.dataTransfer.setData('cursorOffset', offsetX);

          // trigger the drop event on that element
          element.dispatchEvent(dropEvent);
          this.dragEvent = dropEvent;
        }

        const id = +this.clone.getAttribute('data-cardid');
        let row = 0;
        let index = this.wordLine[row].findIndex((data) => data.card?.cardId === id);
        if (index === -1) {
          row = 1;
          index = this.wordLine[row].findIndex((data) => data.card?.cardId === id);
        }

        // remove the clone
        document.body.removeChild(this.clone);
        this.clone = null;
        this.onCardDragEnd(this.dragEvent, index, row);
        this.dragEvent = null;
      }
    },

    cardMovedToFrame(card) {
      // card moved to a frame from row
      for (let row = 0; row < Object.keys(this.wordLine).length; row += 1) {
        const item = this.wordLine[row][card.cardIndex];
        if (item && item.card.cardId === card.cardId) {
          this.wordLine[row].splice(card.cardIndex, 1);
          break;
        }
      }
    },

    dragFrameInRow(e, frameData, index) {
      console.log('frame drag');
      e.dataTransfer.dropEffect = 'move';
      e.dataTransfer.effectAllowed = 'move';
      e.dataTransfer.setData('frameIndex', index);
      e.dataTransfer.setData('itemType', 'frame');
      this.dragStart(e);
      const element = e.target;

      setTimeout(() => {
        element.classList.add('hide');
      });
    },

    // drag card from word line
    dragCardFromRow(e, cardData, index, rowNumber) {
      e.dataTransfer.dropEffect = 'move';
      e.dataTransfer.effectAllowed = 'move';
      const cursorXoffset = e.clientX - e.srcElement.getBoundingClientRect().left;
      e.dataTransfer.setData('cursorOffset', cursorXoffset);

      e.dataTransfer.setData('cardPayload', JSON.stringify(cardData));
      e.dataTransfer.setData('cardIndex', index);
      e.dataTransfer.setData('sourceType', 'row');
      e.dataTransfer.setData('sourceRow', rowNumber);
      this.cardDragging = true;
      this.dropped = false;
    },

    cardDropped() {
      setTimeout(() => {
        this.showSpellingInfo = false;
      }, 5000);
    },
    dragEnter(e) {
      const effect = 'copy';
      e.dataTransfer.dropEffect = 'copy';
      e.dataTransfer.effectAllowed = 'copy';
      if (e.target.classList && e.target.classList.contains('drop-row')) {
        document.getElementsByClassName(`row-help-text ${e.target.id}`)[0].classList.add('in-use');
      }
      return effect;
    },

    dragEnterSecondLine(e) {
      const itemType = e.dataTransfer.getData('itemType');
      let effect = 'copy';
      if (itemType === 'frame') {
        e.dataTransfer.dropEffect = 'none';
        e.dataTransfer.effectAllowed = 'none';
        effect = 'none';
      }
      if (e.target.classList && e.target.classList.contains('drop-row')) {
        document.getElementsByClassName(`row-help-text ${e.target.id}`)[0].classList.add('in-use');
      }
      return effect;
    },

    dragLeave(e) {
      if (e.target.classList && e.target.classList.contains('drop-row')) {
        document.getElementsByClassName(`row-help-text ${e.target.id}`)[0].classList.remove('in-use');
      }
      if (e.target && e.target.style) {
        e.target.style.background = '';
      }
    },

    dragStart(e) {
      const element = e.target;

      setTimeout(() => {
        element.classList.add('hide');
      });
    },

    dragEnd(e) {
      const element = e.srcElement;
      element.classList.remove('hide');
    },

    // eslint-disable-next-line no-unused-vars
    onCardDragEnd(e, cardIndex, rowNumber) {
      // if the card got dropped outside the wordline, remove it.
      if (this.cardDragging) {
        if (!this.dropped) this.wordLine[rowNumber].splice(cardIndex, 1);
        e.dataTransfer.effectAllowed = 'move';
        e.dataTransfer.dropEffect = 'move';
      }
      this.cardDragging = false;
    },

    convertToPercentageInRow(position) {
      return `${(position / parseFloat(getComputedStyle(document.getElementById('drop-rows')).width, 10)) * 100}%`;
    },

    handleDroppedItemToRow(e, rowNumber, targetIsCard) {
      this.dropped = true;
      document.getElementsByClassName(`row-help-text ${e.target.id}`)[0].classList.add('hasHadItemDropped');
      rowNumber = +rowNumber;
      const payload = JSON.parse(e.dataTransfer.getData('cardPayload'));
      const dragSource = e.dataTransfer.getData('dragSource');
      const sourceRow = +e.dataTransfer.getData('sourceRow');
      const sourceType = e.dataTransfer.getData('sourceType');

      const cardData = {
        card: payload.card || payload,
      };

      let dropTarget;
      if (!targetIsCard) {
        dropTarget = e.target;
      } else {
        dropTarget = e.target.parentElement;
        // find the row this card is on
        while (!dropTarget.classList.contains('drop-row')) {
          dropTarget = dropTarget.parentElement;
        }
      }

      const cursorOffset = +e.dataTransfer.getData('cursorOffset');
      if (cardData.card.itemType === 'card') {
        cardData.position = e.clientX - dropTarget.getBoundingClientRect().left - cursorOffset;

        if (!this.cardDragging) {
          cardData.position = this.convertToPercentageInRow(cardData.position);
          this.wordLine[rowNumber].push(cardData);

          // if dropping a card onto top word line, show the spelling options info
          // if it's the first standard sound card that's been dragged up to the row:
          if (!this.SChasBeenDropped && !cardData.card.suffix && this.currentDay === 1) {
            this.updateSpellingOptionsDisplay(true);
            this.showSpellingInfo = true;
          }
        } else if (this.cardDragging) {
          const index = +e.dataTransfer.getData('cardIndex');
          if (dragSource !== 'frame') {
            if (sourceType === 'row' && sourceRow !== rowNumber) {
              // move from one row to another
              this.wordLine[sourceRow] = this.wordLine[sourceRow].filter((card, i) => i !== index);
              cardData.position = this.convertToPercentageInRow(cardData.position);
              this.wordLine[rowNumber] = [...this.wordLine[rowNumber], cardData];
            } else {
              const cardPosition = this.convertToPercentageInRow(cardData.position);
              this.wordLine[rowNumber][index].position = cardPosition;
              this.cardDragging = false;
              if (index === this.spellingOptionsCardIndex) {
                this.SOposition = cardPosition;
                // the SO card index will be at the end of the array
                this.spellingOptionsCardIndex = this.wordLine[rowNumber].length - 1;
              }
              // move the card to the end of the array so it move to the top of the drawn cards
              this.wordLine[rowNumber].push(this.wordLine[rowNumber].splice(index, 1)[0]);
            }
          } else {
            // dragging from frame to word line
            cardData.position = this.convertToPercentageInRow(cardData.position);
            this.wordLine[rowNumber].push(cardData);
          }
        }
      } else if (cardData.card.itemType === 'frame') {
        cardData.card.position = this.convertToPercentageInRow(e.pageX - e.target.getBoundingClientRect().left - cursorOffset);

        if (cardData.card.location === 'stack') {
          // frame dropped in to a row
          cardData.card.location = 'wordline';
          this.activeFrames.push(cardData.card);
          const idx = cardData.card.frameIndex;

          if (cardData.card.frameType === 'sylFrame') {
            const poolIndex = this.syllableFramePool.findIndex((f) => f.frameIndex === idx);
            this.syllableFramePool.splice(poolIndex, 1);
          } else {
            const poolIndex = this.suffixFramePool.findIndex((f) => f.frameIndex === idx);
            this.suffixFramePool.splice(poolIndex, 1);
          }
        } else {
          // moving frame within a row
          const cardIndex = e.dataTransfer.getData('cardIndex');
          const offset = e.dataTransfer.getData('cursorOffset');
          this.activeFrames[cardIndex].position = this.convertToPercentageInRow(e.pageX - e.target.getBoundingClientRect().left - offset);
        }
      }

      // set the proper font size for cards in the rows
      if (!this.SChasBeenDropped) {
        this.resizeRowCards();
      }

      if (!cardData.card.suffix && cardData.card.itemType !== 'frame') {
        this.SChasBeenDropped = true;
      }
      e.target.classList.add('has-child');
    },
    clearWords() {
      this.wordLine[0] = [];
      this.wordLine[1] = [];
      this.removeSpellingOptionsPopunder();
    },

    clearFrames() {
      this.activeFrames = [];
      this.initializeFramePools();
    },

    clearAll() {
      this.clearWords();
      this.clearFrames();
    },
    getCardsForDisplay() {
      const programData = this.programCardData.find((c) => c.program === (this.isFunkFun1 ? 'fun2' : this.programId));
      const dayNumber = this.dayNumber(this.programId, this.unitId, this.weekId, this.dayId, false);
      this.currentDay = dayNumber;
      this.currentProgram = this.programId;
      if (programData) {
        this.cardsToShow = programData.cardData.cards;
        this.setDisplayPosition({ cards: this.cardsToShow });
        const xref = programData.cardData.cardXref.find((f) => f.days.includes(dayNumber));

        if (xref) {
          const excludeBase = !!xref.excludeBase;
          const additionalCardsToShow = programData.cardData.restOfTheCards.filter((element) => xref.cards.includes(element.cardId));
          this.setDisplayPosition({ cards: additionalCardsToShow });
          if (!excludeBase) {
            this.cardsToShow = this.cardsToShow.concat(additionalCardsToShow);
            if (xref.excludeFromBase) {
              this.cardsToShow = this.cardsToShow.filter((f) => !xref.excludeFromBase.includes(f.cardId));
            }
          } else {
            this.cardsToShow = additionalCardsToShow;
          }
          const overrides = xref.positionOverride;
          if (overrides) {
            this.setOverridePosition({ cards: this.cardsToShow, overrides });
          }
          if (xref.alternatePosters && this.weekId !== '0') {
            this.isAltModalVisible = true;
          }
        }

        if (this.programId === 'fun1') {
          // remove cards specific for level 1
          const rem = [55, 56, 47, 48, 49, 50, 51];
          this.cardsToShow = this.cardsToShow.filter((c) => !rem.includes(c.cardId));
        }
      }
    },

    getWordsForWordbank() {
      if (!this.isFunkFun1) {
        const wordsForProgram = this.wordLists.find((l) => l.program === this.programId).words;
        const wfp = wordsForProgram.find((w) => w.day === `${this.unitId}-${this.weekId}-${this.dayId}`);
        if (wfp) {
          this.wordBankWords = wfp.words;
        }
      }
    },

    selectWord(word) {
      const cards = this.getCardsForWord(word);
      this.clearWords();
      cards.forEach((card, idx) => {
        const cardData = {
          card,
          position: `${idx * 5.5 + 0.8}%`,
        };
        this.wordLine[0].push(cardData);
      });
      document.getElementById(`rht-1`).classList.add('in-use');
      this.selectedWord = word.word;
      setTimeout(() => {
        this.closeModal();
        this.selectedWord = '';
        this.SChasBeenDropped = true;
      }, 2000);
    },

    getCardsForWord(word) {
      const cards = [];
      this.clearFrames();

      word.cards.forEach((c, idx) => {
        const cardType = typeof c;
        switch (cardType) {
          // simple sound card
          case 'number': {
            const card = this.cardsToShow.find((cd) => cd.cardId === (c >= 500 ? c - 500 : c));
            card.itemType = 'card';
            card.isBlank = c >= 500;
            cards.push(card);
            break;
          }

          // syllable card
          case 'object': {
            if (Object.keys(c)[0] === 'syl') {
              // syllable frame
              const newFrame = {
                frameType: 'sylFrame',
                frameIndex: this.wordBankFrameIndex,
                location: 'wordline',
                position: `${idx * 21 + 1}%`,
                initialCards: [],
              };
              this.syllableFramePool.splice(idx, 1);
              this.wordBankFrameIndex += 1;

              // handwriting card
              c.syl.forEach((card, $idx) => {
                if (typeof card === 'string') {
                  const cardToAdd = {
                    label: card,
                    itemType: 'hw',
                    color: 'hw-card',
                    cardId: -1,
                  };
                  const cardData = {
                    card: cardToAdd,
                  };
                  newFrame.initialCards.push(cardData);
                } else {
                  // cards inside a syllable frame
                  const cardToAdd = this.cardsToShow.find((cd) => cd.cardId === (card >= 500 ? card - 500 : card));
                  cardToAdd.itemType = 'card';
                  cardToAdd.isBlank = card >= 500;
                  const cardData = {
                    card: cardToAdd,
                    offset: $idx * 22 + 2,
                  };
                  newFrame.initialCards.push(cardData);
                }
              });
              this.activeFrames.push(newFrame);
            }
            break;
          }

          default:
            break;
        }
      }, this);
      return cards;
    },

    showModal() {
      this.isModalVisible = true;
    },

    closeModal() {
      this.isModalVisible = false;
      this.isAltModalVisible = false;
    },
    resizeRowCards() {
      const rowHeight = document.getElementById('row-1').clientHeight;
      document.getElementById('drop-rows').style['font-size'] = `${rowHeight * 0.3}px`;
    },
    resizeFonts() {
      // resize fonts of cards in the lower card deck
      const cardHeight = document.getElementById('cards-container').getElementsByClassName('sc')[0].clientHeight;

      const x = document.getElementById('cards-container').getElementsByClassName('sc-letter');
      for (let i = 0; i < x.length; i += 1) {
        x[i].setAttribute('style', `font-size: ${cardHeight * 0.37}px`);
      }

      // resize fonts of cards in droprow area
      this.resizeRowCards();

      // resize fonts of HANDWRITING cards
      if (document.getElementsByClassName('hw-card')[0]) {
        const hwCardHeight = document.getElementById('drop-rows').getElementsByClassName('sylFrame')[0].clientHeight;
        const hwCards = document.getElementsByClassName('hw-card');
        for (let index = 0; index < hwCards.length; index += 1) {
          hwCards[index].style['font-size'] = `${hwCardHeight * 0.8}px`;
        }
      }
    },

    resizeMethods() {
      this.resizeCards();
      this.resizeFonts();
    },

    appendSpellingOptionsPopunder(e, index, color) {
      if (this.isFunkFun1) {
        return;
      }
      const popUnders = Array.from(document.querySelectorAll('.sc.has-popunder'));
      popUnders.forEach((popUnderElement) => popUnderElement.classList.remove('has-popunder'));

      let el = e.target;
      while (!el.classList.contains('sc')) {
        el = el.parentElement;
      }
      el.classList.add('has-popunder');

      if (color !== 'suffix-yellow') {
        this.spellingOptionState = 'visible';
        const clickedCardPosition = el.style.left;
        this.SOposition = clickedCardPosition;
        this.spellingOptionsCardIndex = index;
        this.clearSpellingOptionCards = !this.clearSpellingOptionCards;
      }
      this.showSpellingInfo = false;
    },

    removeSpellingOptionsPopunder() {
      this.spellingOptionState = 'hidden';
      this.spellingOptionsCardIndex = -1;
      // TODO: Remove cards from pop-under
    },
    showSopLabelsToday() {
      if (
        this.showSpellingInfo
        && ((this.currentDay === 49 && this.currentProgram === 'fun2') || (this.currentDay === 27 && this.currentProgram === 'fun3'))
      ) {
        return true;
      }
      return false;
    },
    cardsAreInRows() {
      if (
        document.getElementById('row-1').contains(document.getElementsByClassName('sc')[0])
        || document.getElementById('row-1').contains(document.getElementsByClassName('sufFrame')[0])
        || document.getElementById('row-1').contains(document.getElementsByClassName('sylFrame')[0])
        || document.getElementById('row-2').contains(document.getElementsByClassName('sc')[0])
        || document.getElementById('row-2').contains(document.getElementsByClassName('sufFrame')[0])
        || document.getElementById('row-2').contains(document.getElementsByClassName('sylFrame')[0])
      ) {
        return true;
      }
      return false;
    },
    showResizeCautionMessage() {
      if (!this.resizeCautionMessageAlreadyShown) {
        if (this.cardsAreInRows()) {
          this.resizeCautionMessageAlreadyShown = true;
          this.resizeCautionMessage = true;
          setTimeout(() => {
            this.resizeCautionMessage = false;
          }, 5000);
        }
      }
    },
  },
};
</script>
<style lang="scss">
  .altPostersModal{
    .modal-header{
      display: none;
    }
    .modal-body{
      text-align: center;
      font-size: 2rem;
      padding: 40px;
      #reference-posters-shortcut{
        max-width: 30rem;
        margin: 5rem auto 0 auto;
      }
    }
  }
  .modal-body {
    text-align: center;
    font-size: 2rem;
    padding: 40px;
    #reference-posters-shortcut {
      margin-top: 50px;
      max-width: 30rem;
      margin: 5rem auto 0 auto;
    }
  }

.hide {
  transform: translateX(-9999px);
}
</style>
<style lang="scss" scoped>
@import '~@/styles/standardcards';

#app {
  #positioner {
    height: calc(100% + 3rem);
  }
}
#card-positioner {
  right: 20%;
}
#standard-card-buttons {
  position: absolute;
  left: 70%;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  margin-left: $activity-frame-lateral-padding;
  button {
    margin: 0.5rem;
    padding-left: 3rem;
    padding-right: 3rem;
  }
}
.Make-Words-For-Decoding {
  .button-container {
    justify-content: flex-start;
    #online-dictionary {
      margin-top: 15px;
    }
  }
}
.wordbank-item {
  cursor: pointer;
}
.sc {
  cursor: pointer;
}
#tap-explainer {
  position: absolute;
  left: 75%;
  width: 16rem;
  display: flex;
  align-content: center;
  opacity: 0;
  animation: fadeout 3s ease-in-out;
  z-index: 100;
  &:before {
    content: '';
    background-color: $yellow-card;
    border-radius: 50%;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: scale(0.001, 0.001);
    transform-origin: left;
    outline: 0;
    color: #fff;
    animation: flash 0.8s ease-out;
    z-index: 99;
  }
  img {
    width: 3rem;
  }
}
#row-1 {
  .sc:not(:first-of-type) {
    #tap-explainer {
      display: none;
    }
  }
}
@keyframes flash {
  50% {
    transform: scale(1.5, 1.5);
    opacity: 0;
  }
  99% {
    transform: scale(0.001, 0.001);
    opacity: 0;
  }
  100% {
    transform: scale(0.001, 0.001);
    opacity: 1;
  }
}
@keyframes fadeout {
  0% {
    transform: scale(1, 1);
    opacity: 1;
  }
  75% {
    transform: scale(1, 1);
    opacity: 1;
  }
  100% {
    transform: scale(0.8, 0.8);
    opacity: 0;
  }
}

@media (max-width: 900px) {
  #standard-card-buttons {
    margin-left: $activity-frame-lateral-padding;
  }
}
.button-container {
  position: absolute;
  left: 80%;
  right: 0;
  padding: 0 2rem;
  flex-direction: column;
  display: flex;
  max-height: 400px;
  height: 100%;
  justify-content: space-between;

  button:not(.plain-button) {
    width: 100%;
    margin: 1rem auto 0 auto;
    .funk & {
      background-color: $fun-blue;
    }
    .fun1 & {
      background-color: $fun-salmon;
    }
    .fun2 & {
      background-color: $fun-green;
    }
    .fun3 & {
      background-color: $fun-purple;
    }
  }
  & > * {
    margin: 0 auto;
  }
  & > .sdp {
    margin: -9rem auto 5rem auto;
    display: block;
    text-align: center;
    font-size: 2rem;
    font-style: italic;
  }
  button {
    position: relative;
    font-weight: 400;
    font-size: 1.8rem;
  }
  .button-image-holder {
    display: block;
    flex: 1 0 auto;
    position: relative;
    margin: auto 0 0 0;
    display: block;
    align-self: normal;
  }
  .button-image-holder:before,
  .button-image-holder:after,
  .word-bank-toggle:before {
    content: '';
    width: 100%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
  }

  .clear-cards-toggle .button-image-holder {
    &:before {
      background-image: url('/images/clearcards.svg');
    }
    &:after {
      background-image: url(/images/clear-big.svg);
    }
  }
  .clear-frames-toggle {
    .button-image-holder {
      &:before {
        background-image: url('/images/clearframes.svg');
      }
      &:after {
        background-image: url(/images/clear-big.svg);
        opacity: 0.4;
      }
    }
  }
  .clear-all-toggle .button-image-holder:before {
    background-image: url('/images/trash.svg');
  }
  .word-bank-toggle {
    flex: 0 0 25%;
    width: 33%;
    min-width: 90px;
    align-content: flex-end;
    justify-content: flex-end;
    display: flex;
    flex-direction: column;
    border-radius: 50%;
    background-image: radial-gradient(circle, #fffca7, white);
    &:before {
      background-image: url('/images/box.svg');
      flex: 1 0 auto;
      position: relative;
    }
    &.no-words-today {
      opacity: 0.2;
      font-style: italic;
    }
    span {
      z-index: 1;
    }
  }
}
#clear-buttons {
  display: flex;
  flex: 0 0 25%;

  button {
    display: flex;
    flex-direction: column;
    &:first-of-type {
      margin-left: 0;
    }
    &:last-of-type {
      margin-right: 0;
    }
  }
  span {
    margin-top: auto;
  }
}
@media (max-width: 1000px) {
  #clear-buttons {
    span {
      font-size: 1.5rem;
    }
  }
}

#frames-container {
  max-width: 20rem;
  flex: 0 1 auto;
}
#online-dictionary {
  flex: 0 1 auto;
  text-align: center;
}
#drop-rows {
  position: relative;
  flex: 0 0 20%;
  .increased-row-height & {
    flex: 0 0 25%;
  }
  .decreased-row-height & {
    flex: 0 0 15%;
  }
  .sc {
    font-size: unset;
  }
}
#drop-rows-help-text {
  justify-content: center;
  justify-items: center;
  .row-help-text {
    @extend %headings-shared;
    font-size: 1.3rem;
    text-shadow: 1px 4px 4px #ffff8e;
    transition: all 250ms ease-in-out;
    margin: auto 2%;
  }
}
#drop-rows-help-text,
#phantom-drop-rows {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
}
#phantom-drop-rows {
  & > div {
    flex: 1 0 auto;
  }
  #pdr-1 {
    background: #f2f2f2;
  }
  #pdr-2 {
    background: #f2f2f2;
  }
}
.drop-row {
  height: calc(50% - 1.05rem);
  // padding-bottom: 8%;
  border-top: solid 0.7rem #cccccc;
  @media (max-height: 700px) {
    height: calc(50% - 0.45rem);
    border-top: solid 0.3rem #cccccc;
  }
  position: relative;

  &:last-of-type {
    border-bottom: solid 0.7rem #cccccc;
    @media (max-height: 700px) {
      border-bottom: solid 0.3rem #cccccc;
    }
  }
  .sc {
    width: 2.4em;
    top: 0.5rem;
    bottom: 0.5rem;
    position: absolute;
    z-index: 10;
    .increased-row-height & {
      width: 2.25em;
    }
    .decreased-row-height & {
      width: 1.85em;
    }
    .sc-letter {
      position: relative;
    }
    &.suffix-yellow {
      width: 11%;
      width: 5em;
      .increased-row-height & {
        width: 9em;
      }
      .decreased-row-height & {
        width: 4em;
      }
    }
    &.has-popunder {
      z-index: 99999;

      &.sop-covered {
        z-index: 1;
      }
    }
  }
  .sylFrame,
  .sufFrame {
    top: 0.1rem;
    bottom: 0.1rem;
  }
  .sylFrame {
    width: 12em;
    .increased-row-height & {
      width: 9em;
    }
    .decreased-row-height & {
      width: 13em;
    }
  }
  .sufFrame {
    width: 14%;
    .increased-row-height & {
      width: 19%;
    }
    .increased-row-height & {
      width: 12%;
    }
  }
}
.row-help-text {
  &.standby-for-in-use {
    color: $med-gray;
  }
  &.in-use,
  &.hasHadItemDropped {
    opacity: 0;
  }
}
.wordbank-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
}
.wordbank-item {
  font-family: wManuscript;
  font-size: 20pt;
  text-align: center;
  cursor: pointer;
  color: white;
  background: #46b2ff;
  border-radius: 165px;
  margin: 0.5rem 1rem;
  padding: 1.4rem;
  transition: all 1s ease-out;
  .has-selected-word & {
    background: none;
  }
  &.selected-word {
    background: none;
    color: black;
    letter-spacing: 2rem;
    transform: scale(1.5);
  }
}
.modal {
  .btn {
    transition: opacity 1000ms ease-out;
  }
}

.has-selected-word {
  .btn {
    opacity: 0;
  }
}
#sylFrames,
#sufFrames {
  padding-bottom: 35%;
  position: relative;
  margin-bottom: 1rem;
  cursor: pointer;
  .sylFrame,
  .sufFrame {
    &:nth-of-type(1) {
      top: -0.5rem;
      right: 0.5rem;
      bottom: 0.5rem;
      left: -0.5rem;
    }
    &:nth-of-type(2) {
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
    &:nth-of-type(3) {
      top: 0.5rem;
      right: -0.5rem;
      bottom: -0.5rem;
      left: 0.5rem;
    }
  }
}
.frames-helper-text {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  transition: all 150ms ease-in-out;

  .frames-label {
    margin: auto auto 1rem auto;
    @extend %headings-shared;
    color: $red-accent;
    white-space: nowrap;
  }
  .frames-guide {
    margin: 0 auto auto auto;
    color: $med-gray;
    text-align: center;
    line-height: 0.9;
    font-style: italic;
  }
}
#sufFrames:hover,
#sylFrames:hover {
  .frames-helper-text {
    opacity: 0.2;
  }
}
#sufFrames:active,
#sylFrames:active {
  .frames-helper-text {
    display: none;
  }
}
.sylFrame,
.sufFrame {
  position: absolute;
  border: solid 1px gray;
  cursor: pointer;
}
.sylFrame {
  background-color: white;
  cursor: pointer;
}
.sufFrame {
  background-color: lightgray;
  cursor: pointer;
}
button.remove-frame {
  padding: 0.2rem;
  font-size: 1rem;
  z-index: 11;
  background: #b9b9b9;
  color: rgb(0, 0, 0);
  margin: 0.1rem;
}
.resizeCautionMessage {
  position: fixed;
  left: 40px;
  max-width: 25%;
  transform: translateX(-100%);
}
</style>
