/*
ŭ - &ubreve;
ū - &umacr;
ü - &uuml;
ĭ - &#301;
ə - &#601;
ĕ - &#277;
ē - &emacr;
ā - &amacr;
ă - &abreve;
ī - &imacr;
ŏ - &#335;
ō - &omacr;
ȯ - &#559;
*/

export default {
  namespaced: true,
  state: {
    cardData: [
      {
        program: 'fun2',
        //
        cards: [
          { soundFile: 'a.mp3', cardId: 1, label: 'Aa', color: 'red-card', vowel: 1, col: 1, row: 1, image: '/Fun1/illustrations/apple', include: 1, lks: 'a apple /&abreve;/', keyword: 'apple' },
          { soundFile: 'a.mp3', cardId: 101, label: 'a-e', color: 'red-card', vowel: 1, col: 2, row: 1, image: '/Fun2/illustrations/safe', include: 47, lks: 'a safe /&amacr;/', keyword: 'safe' },
          { soundFile: 'a.mp3', cardId: 201, label: 'Aa', color: 'red-card', vowel: 1, col: 3, row: 1, image: '/Fun2/illustrations/acorn', include: 57, lks: 'a acorn /&amacr;/', keyword: 'acorn' },
          { soundFile: 'a.mp3', cardId: 2, label: 'Bb', color: 'yellow-card', vowel: 0, col: 4, row: 1, image: '/Fun1/illustrations/bat', include: 1, lks: 'b bat /b/', keyword: 'bat' },
          { soundFile: 'a.mp3', cardId: 3, label: 'Cc', color: 'yellow-card', vowel: 0, col: 5, row: 1, image: '/Fun1/illustrations/cat', include: 1, lks: 'c cat /k/', keyword: 'cat' },
          { soundFile: 'a.mp3', cardId: 4, label: 'Dd', color: 'yellow-card', vowel: 0, col: 6, row: 1, image: '/Fun1/illustrations/dog', include: 1, lks: 'd dog /d/', keyword: 'dog' },
          { soundFile: 'a.mp3', cardId: 5, label: 'Ee', color: 'red-card', vowel: 1, col: 7, row: 1, image: '/Fun1/illustrations/Ed', include: 1, lks: 'e Ed /&#277;/', keyword: 'Ed' },
          { soundFile: 'a.mp3', cardId: 105, label: 'e-e', color: 'red-card', vowel: 1, col: 8, row: 1, image: '/Fun1/illustrations/pete', include: 47, lks: 'e Pete /&emacr;/', keyword: 'pete' },
          { soundFile: 'a.mp3', cardId: 205, label: 'Ee', color: 'red-card', vowel: 1, col: 9, row: 1, image: '/Fun2/illustrations/me', include: 57, lks: 'e me /&emacr;/', keyword: 'me' },
          { soundFile: 'a.mp3', cardId: 6, label: 'Ff', color: 'yellow-card', vowel: 0, col: 10, row: 1, image: '/Fun1/illustrations/Fun', include: 1, lks: 'f fun /f/', keyword: 'fun' },
          { soundFile: 'a.mp3', cardId: 9, label: 'Gg', color: 'yellow-card', vowel: 0, col: 1, row: 2, image: '/Fun1/illustrations/game', include: 1, lks: 'g game /g/', keyword: 'game' },
          { soundFile: 'a.mp3', cardId: 10, label: 'Hh', color: 'yellow-card', vowel: 0, col: 2, row: 2, image: '/Fun1/illustrations/hat', include: 1, lks: 'h hat /h/', keyword: 'hat' },
          { soundFile: 'a.mp3', cardId: 11, label: 'Ii', color: 'red-card', vowel: 1, col: 3, row: 2, image: '/Fun1/illustrations/itch', include: 1, lks: 'i itch /&#301;/', keyword: 'itch' },
          { soundFile: 'a.mp3', cardId: 111, label: 'i-e', color: 'red-card', vowel: 1, col: 4, row: 2, image: '/Fun1/illustrations/pine', include: 47, lks: 'i pine /&imacr;/', keyword: 'pine' },
          { soundFile: 'a.mp3', cardId: 211, label: 'Ii', color: 'red-card', vowel: 1, col: 5, row: 2, image: '/Fun2/illustrations/hi', include: 57, lks: 'i hi /&imacr;/', keyword: 'hi' },
          { soundFile: 'a.mp3', cardId: 12, label: 'Jj', color: 'yellow-card', vowel: 0, col: 6, row: 2, image: '/Fun1/illustrations/jug', include: 1, lks: 'j jug /j/', keyword: 'jug' },
          { soundFile: 'a.mp3', cardId: 13, label: 'Kk', color: 'yellow-card', vowel: 0, col: 7, row: 2, image: '/Fun1/illustrations/kite', include: 1, lks: 'k kite /k/', keyword: 'kite' },
          { soundFile: 'a.mp3', cardId: 14, label: 'Ll', color: 'yellow-card', vowel: 0, col: 8, row: 2, image: '/Fun1/illustrations/lamp', include: 1, lks: 'l lamp /l/', keyword: 'lamp' },
          { soundFile: 'a.mp3', cardId: 17, label: 'Mm', color: 'yellow-card', vowel: 0, col: 1, row: 3, image: '/Fun1/illustrations/man', include: 1, lks: 'm man /m/', keyword: 'man' },
          { soundFile: 'a.mp3', cardId: 18, label: 'Nn', color: 'yellow-card', vowel: 0, col: 2, row: 3, image: '/Fun1/illustrations/nut', include: 1, lks: 'n nut /n/', keyword: 'nut' },
          { soundFile: 'a.mp3', cardId: 19, label: 'Oo', color: 'red-card', vowel: 1, col: 3, row: 3, image: '/Fun1/illustrations/octopus', include: 1, lks: 'o octopus /&#335;/', keyword: 'octopus' },
          { soundFile: 'a.mp3', cardId: 119, label: 'o-e', color: 'red-card', vowel: 1, col: 4, row: 3, image: '/Fun1/illustrations/home', include: 47, lks: 'o home /&omacr;/', keyword: 'home' },
          { soundFile: 'a.mp3', cardId: 219, label: 'Oo', color: 'red-card', vowel: 1, col: 5, row: 3, image: '/Fun2/illustrations/no', include: 57, lks: 'o no /&omacr;/', keyword: 'no' },
          { soundFile: 'a.mp3', cardId: 20, label: 'Pp', color: 'yellow-card', vowel: 0, col: 6, row: 3, image: '/Fun1/illustrations/pan', include: 1, lks: 'p pan /p/', keyword: 'pan' },
          { soundFile: 'a.mp3', cardId: 21, label: 'QU<br>qu', color: 'yellow-card', vowel: 0, col: 7, row: 3, image: '/Fun1/illustrations/queen', include: 1, lks: 'qu queen /kw/', keyword: 'queen' },
          { soundFile: 'a.mp3', cardId: 22, label: 'Rr', color: 'yellow-card', vowel: 0, col: 8, row: 3, image: '/Fun1/illustrations/rat', include: 1, lks: 'r rat /r/', keyword: 'rat' },
          { soundFile: 'a.mp3', cardId: 23, label: 'Ss', color: 'yellow-card', vowel: 0, col: 9, row: 3, image: '/Fun1/illustrations/snake', include: 1, lks: 's snake /s/', keyword: 'snake' },
          { soundFile: 'a.mp3', cardId: 123, label: 'Ss', color: 'yellow-card', vowel: 0, col: 10, row: 3, image: '/Fun1/illustrations/bugs', include: 31, lks: 's bugs /z/', keyword: 'bugs' },
          { soundFile: 'a.mp3', cardId: 26, label: 'Tt', color: 'yellow-card', vowel: 0, col: 1, row: 4, image: '/Fun1/illustrations/top', include: 1, lks: 't top /t/', keyword: 'top' },
          { soundFile: 'a.mp3', cardId: 27, label: 'Uu', color: 'red-card', vowel: 1, col: 2, row: 4, image: '/Fun1/illustrations/up', include: 1, lks: 'u up /&ubreve;/', keyword: 'up' },
          { soundFile: 'a.mp3', cardId: 127, label: 'u-e', color: 'red-card', vowel: 1, col: 3, row: 4, image: '/Fun1/illustrations/mule', include: 47, lks: 'u mule /&umacr;/', keyword: 'mule' },
          { soundFile: 'a.mp3', cardId: 227, label: 'u-e', color: 'red-card', vowel: 1, col: 4, row: 4, image: '/Fun1/illustrations/rule', include: 47, lks: 'u rule /&uuml;/', keyword: 'rule' },
          { soundFile: 'a.mp3', cardId: 327, label: 'Uu', color: 'red-card', vowel: 1, col: 5, row: 4, image: '/Fun2/illustrations/student', include: 57, lks: 'u pupil /&umacr;/', keyword: 'pupil' },
          { soundFile: 'a.mp3', cardId: 427, label: 'Uu', color: 'red-card', vowel: 1, col: 6, row: 4, image: '/Fun2/illustrations/flu', include: 57, lks: 'u flu /&uuml;/', keyword: 'flu' },
          { soundFile: 'a.mp3', cardId: 28, label: 'Vv', color: 'yellow-card', vowel: 0, col: 7, row: 4, image: '/Fun1/illustrations/van', include: 1, lks: 'v van /v/', keyword: 'van' },
          { soundFile: 'a.mp3', cardId: 29, label: 'Ww', color: 'yellow-card', vowel: 0, col: 8, row: 4, image: '/Fun1/illustrations/wind', include: 1, lks: 'w wind /w/', keyword: 'wind' },
          { soundFile: 'a.mp3', cardId: 30, label: 'Xx', color: 'yellow-card', vowel: 0, col: 9, row: 4, image: '/Fun1/illustrations/fox', include: 1, lks: 'x fox /ks/', keyword: 'fox' },
          { soundFile: 'a.mp3', cardId: 31, label: 'Yy', color: 'yellow-card', vowel: 0, col: 10, row: 4, image: '/Fun1/illustrations/yellow', include: 1, lks: 'y yellow /y/', keyword: 'yellow' },
          { soundFile: 'a.mp3', cardId: 32, label: 'Zz', color: 'yellow-card', vowel: 0, col: 11, row: 4, image: '/Fun1/illustrations/zebra', include: 1, lks: 'z zebra /z/', keyword: 'zebra' },
          { soundFile: 'a.mp3', cardId: 35, label: 'wh', color: 'yellow-card', vowel: 0, col: 1, row: 5, image: '/Fun1/illustrations/whistle', include: 1, lks: 'wh whistle /w/', keyword: 'whistle' },
          { soundFile: 'a.mp3', cardId: 36, label: 'ch', color: 'yellow-card', vowel: 0, col: 2, row: 5, image: '/Fun1/illustrations/chin', include: 1, lks: 'ch chin /ch/', keyword: 'chin' },
          { soundFile: 'a.mp3', cardId: 37, label: 'sh', color: 'yellow-card', vowel: 0, col: 3, row: 5, image: '/Fun1/illustrations/ship', include: 1, lks: 'sh ship /sh/', keyword: 'ship' },
          { soundFile: 'a.mp3', cardId: 38, label: 'th', color: 'yellow-card', vowel: 0, col: 4, row: 5, image: '/Fun1/illustrations/thumb', include: 1, lks: 'th thumb /th/', keyword: 'thumb' },
          { soundFile: 'a.mp3', cardId: 39, label: 'ck', color: 'yellow-card', vowel: 0, col: 5, row: 5, image: '/Fun1/illustrations/sock', include: 1, lks: 'ck sock /k/', keyword: 'sock' },
          { soundFile: 'a.mp3', cardId: 7, label: 'ang', color: 'green-card', vowel: 0, col: 11, row: 1, image: '/Fun1/illustrations/fang', include: 18, lks: 'ang fang /ang/', keyword: 'fang' },
          { soundFile: 'a.mp3', cardId: 8, label: 'ank', color: 'green-card', vowel: 0, col: 12, row: 1, image: '/Fun1/illustrations/bank', include: 18, lks: 'ank bank /ank/', keyword: 'bank' },
          { soundFile: 'a.mp3', cardId: 15, label: 'ing', color: 'green-card', vowel: 0, col: 9, row: 2, image: '/Fun1/illustrations/ring', include: 18, lks: 'ing ring /ing/', keyword: 'ring' },
          { soundFile: 'a.mp3', cardId: 16, label: 'ink', color: 'green-card', vowel: 0, col: 10, row: 2, image: '/Fun1/illustrations/pink', include: 18, lks: 'ink pink /ink/', keyword: 'pink' },
          { soundFile: 'a.mp3', cardId: 24, label: 'ong', color: 'green-card', vowel: 0, col: 11, row: 3, image: '/Fun1/illustrations/song', include: 18, lks: 'ong song /ong/', keyword: 'song' },
          { soundFile: 'a.mp3', cardId: 25, label: 'onk', color: 'green-card', vowel: 0, col: 12, row: 3, image: '/Fun1/illustrations/honk', include: 18, lks: 'onk honk /onk/', keyword: 'honk' },
          { soundFile: 'a.mp3', cardId: 33, label: 'ung', color: 'green-card', vowel: 0, col: 12, row: 4, image: '/Fun1/illustrations/lung', include: 18, lks: 'ung lung /ung/', keyword: 'lung' },
          { soundFile: 'a.mp3', cardId: 34, label: 'unk', color: 'green-card', vowel: 0, col: 13, row: 4, image: '/Fun1/illustrations/junk', include: 18, lks: 'unk junk /unk/', keyword: 'junk' },
          { soundFile: 'a.mp3', cardId: 40, label: 'Yy', color: 'red-card', vowel: 0, col: 6, row: 5, image: '/Fun2/illustrations/babycry', include: 57, lks: 'y cry /&imacr;/<br>y baby /&emacr;/', keyword: 'cry baby' },
          { soundFile: 'a.mp3', cardId: 41, label: 'ai', color: 'red-card', vowel: 0, col: 7, row: 5, image: '/Fun2/illustrations/bait', include: 87, lks: 'ai bait /&amacr;/', keyword: 'bait' },
          { soundFile: 'a.mp3', cardId: 42, label: 'ay', color: 'red-card', vowel: 0, col: 8, row: 5, image: '/Fun2/illustrations/play', include: 87, lks: 'ay play /&amacr;/', keyword: 'play' },
          { soundFile: 'a.mp3', cardId: 43, label: 'all', color: 'green-card', vowel: 0, col: 1, row: 6, image: '/Fun1/illustrations/ball', include: 12, lks: 'all ball /&#559;l/', keyword: 'ball' },
          { soundFile: 'a.mp3', cardId: 44, label: 'am', color: 'green-card', vowel: 0, col: 2, row: 6, image: '/Fun1/illustrations/ham', include: 17, lks: 'am ham /am/', keyword: 'ham' },
          { soundFile: 'a.mp3', cardId: 45, label: 'an', color: 'green-card', vowel: 0, col: 3, row: 6, image: '/Fun1/illustrations/fan', include: 17, lks: 'an fan /an/', keyword: 'fan' },
          { soundFile: 'a.mp3', cardId: 46, label: 'ee', color: 'red-card', vowel: 0, col: 4, row: 6, image: '/Fun2/illustrations/jeep', include: 97, lks: 'ee jeep /&emacr;/', keyword: 'jeep' },
          { soundFile: 'a.mp3', cardId: 47, label: 'ea', color: 'red-card', vowel: 0, col: 5, row: 6, image: '/Fun2/illustrations/eat', include: 97, lks: 'ea eat /&emacr;/', keyword: 'eat' },
          { soundFile: 'a.mp3', cardId: 48, label: 'ey', color: 'red-card', vowel: 0, col: 6, row: 6, image: '/Fun2/illustrations/key', include: 97, lks: 'ey key /&emacr;/', keyword: 'key' },
          { soundFile: 'a.mp3', cardId: 49, label: 'ind', color: 'green-card', vowel: 0, col: 1, row: 7, image: '/Fun2/illustrations/find', include: 22, lks: 'ind find /&imacr;nd/', keyword: 'find' },
          { soundFile: 'a.mp3', cardId: 50, label: 'ild', color: 'green-card', vowel: 0, col: 2, row: 7, image: '/Fun2/illustrations/wild', include: 22, lks: 'ild wild /&imacr;ld/', keyword: 'wild' },
          { soundFile: 'a.mp3', cardId: 51, label: 'old', color: 'green-card', vowel: 0, col: 3, row: 7, image: '/Fun2/illustrations/cold', include: 22, lks: 'old cold /&omacr;ld/', keyword: 'cold' },
          { soundFile: 'a.mp3', cardId: 52, label: 'olt', color: 'green-card', vowel: 0, col: 4, row: 7, image: '/Fun2/illustrations/colt', include: 22, lks: 'olt colt /&omacr;lt/', keyword: 'colt' },
          { soundFile: 'a.mp3', cardId: 53, label: 'ost', color: 'green-card', vowel: 0, col: 5, row: 7, image: '/Fun2/illustrations/post', include: 22, lks: 'ost post /&omacr;st/', keyword: 'post' },
          { soundFile: 'a.mp3', cardId: 54, label: 'ive', color: 'green-card', vowel: 0, col: 6, row: 7, image: '/Fun2/illustrations/give', include: 53, lks: 'ive give /&#301;v/', keyword: 'give' },
          { soundFile: 'a.mp3', cardId: 55, label: 'oi', color: 'red-card', vowel: 0, col: 7, row: 7, image: '/Fun2/illustrations/coin', include: 107, lks: 'oi coin /oi/', keyword: 'coin' },
          { soundFile: 'a.mp3', cardId: 56, label: 'oy', color: 'red-card', vowel: 0, col: 8, row: 7, image: '/Fun2/illustrations/boy', include: 107, lks: 'oy boy /oi/', keyword: 'boy' },
          { soundFile: 'a.mp3', cardId: 57, label: 'ar', color: 'red-card', vowel: 0, col: 1, row: 8, image: '/Fun2/illustrations/car', include: 72, lks: 'ar car /ar/', keyword: 'car' },
          { soundFile: 'a.mp3', cardId: 58, label: 'or', color: 'red-card', vowel: 0, col: 2, row: 8, image: '/Fun2/illustrations/horn', include: 72, lks: 'or horn /or/', keyword: 'horn' },
          { soundFile: 'a.mp3', cardId: 59, label: 'er', color: 'red-card', vowel: 0, col: 3, row: 8, image: '/Fun2/illustrations/her', include: 77, lks: 'er her /&#601;r/', keyword: 'her' },
          { soundFile: 'a.mp3', cardId: 60, label: 'ir', color: 'red-card', vowel: 0, col: 4, row: 8, image: '/Fun2/illustrations/bird', include: 77, lks: 'ir bird /&#601;r/', keyword: 'bird' },
          { soundFile: 'a.mp3', cardId: 61, label: 'ur', color: 'red-card', vowel: 0, col: 5, row: 8, image: '/Fun2/illustrations/burn', include: 77, lks: 'ur burn /&#601;r/', keyword: 'burn' },
          { soundFile: 'a.mp3', cardId: 62, label: 'oa', color: 'red-card', vowel: 0, col: 6, row: 8, image: '/Fun2/illustrations/boat', include: 112, lks: 'oa boat /&omacr;/', keyword: 'boat' },
          { soundFile: 'a.mp3', cardId: 63, label: 'oe', color: 'red-card', vowel: 0, col: 7, row: 8, image: '/Fun2/illustrations/toe', include: 112, lks: 'oe toe /&omacr;/', keyword: 'toe' },
          { soundFile: 'a.mp3', cardId: 64, label: 'ow', color: 'red-card', vowel: 0, col: 8, row: 8, image: '/Fun2/illustrations/snowplow', include: 112, lks: 'ow snow /&omacr;/<br>ow plow /ou/', keyword: 'snow' },
          { soundFile: 'a.mp3', cardId: 65, label: 'ou', color: 'red-card', vowel: 0, col: 1, row: 9, image: '/Fun2/illustrations/troutsoup', include: 122, lks: 'ou trout /ou/<br>ou soup /&uuml;/', keyword: 'trout' },
          { soundFile: 'a.mp3', cardId: 66, label: 'oo', color: 'red-card', vowel: 0, col: 2, row: 9, image: '/Fun2/illustrations/oo_schoolbook', include: 132, lks: 'oo school /&uuml;/<br>oo book /&#367;/', keyword: 'school' },
          { soundFile: 'a.mp3', cardId: 67, label: 'ue', color: 'red-card', vowel: 0, col: 3, row: 9, image: '/Fun2/illustrations/ue_bluerescue', include: 132, lks: 'ue blue /&uuml;/<br>ue rescue /&umacr;/', keyword: 'blue' },
          { soundFile: 'a.mp3', cardId: 68, label: 'ew', color: 'red-card', vowel: 0, col: 4, row: 9, image: '/Fun2/illustrations/chew', include: 132, lks: 'ew chew /&uuml;/', keyword: 'chew' },
          { soundFile: 'a.mp3', cardId: 69, label: 'au', color: 'red-card', vowel: 0, col: 1, row: 10, image: '/Fun2/illustrations/august', include: 142, lks: 'au August /&#559;/', keyword: 'august' },
          { soundFile: 'a.mp3', cardId: 70, label: 'aw', color: 'red-card', vowel: 0, col: 2, row: 10, image: '/Fun2/illustrations/saw', include: 142, lks: 'aw saw /&#559;/', keyword: 'saw' },
        ],
      },
      {
        program: 'fun3',
        cards: [
          { soundFile: 'a.mp3', cardId: 1, label: 'Aa', color: 'red-card', vowel: 1, col: 1, row: 1, image: '/Fun1/illustrations/apple', include: 1, lks: 'a apple /&abreve;/', keyword: 'apple' },
          { soundFile: 'a.mp3', cardId: 302, label: 'Aa', color: 'red-card', vowel: 1, col: 5, row: 1, image: '/Fun3/illustrations/wash_squash', include: 7, lks: 'a squash /&#559;/', keyword: 'squash' },
          { soundFile: 'a.mp3', cardId: 101, label: 'a-e', color: 'red-card', vowel: 1, col: 2, row: 1, image: '/Fun2/illustrations/safe', include: 26, lks: 'a safe /&amacr;/', keyword: 'safe' },
          { soundFile: 'a.mp3', cardId: 201, label: 'Aa', color: 'red-card', vowel: 1, col: 3, row: 1, image: '/Fun2/illustrations/acorn', include: 52, lks: 'a acorn /&amacr;/', keyword: 'acorn' },
          { soundFile: 'a.mp3', cardId: 301, label: 'Aa', color: 'red-card', vowel: 1, col: 4, row: 1, image: '/Fun3/illustrations/alaska', include: 56, lks: 'a Alaska /&ubreve;/', keyword: 'alaska' },
          { soundFile: 'a.mp3', cardId: 2, label: 'Bb', color: 'yellow-card', vowel: 0, col: 6, row: 1, image: '/Fun1/illustrations/bat', include: 1, lks: 'b bat /b/', keyword: 'bat' },
          { soundFile: 'a.mp3', cardId: 3, label: 'Cc', color: 'yellow-card', vowel: 0, col: 7, row: 1, image: '/Fun1/illustrations/cat', include: 1, lks: 'c cat /k/', keyword: 'cat' },
          { soundFile: 'a.mp3', cardId: 4, label: 'Dd', color: 'yellow-card', vowel: 0, col: 8, row: 1, image: '/Fun1/illustrations/dog', include: 1, lks: 'd dog /d/', keyword: 'dog' },
          { soundFile: 'a.mp3', cardId: 5, label: 'Ee', color: 'red-card', vowel: 1, col: 9, row: 1, image: '/Fun1/illustrations/Ed', include: 1, lks: 'e Ed /&#277;/', keyword: 'ed' },
          { soundFile: 'a.mp3', cardId: 105, label: 'e-e', color: 'red-card', vowel: 1, col: 10, row: 1, image: '/Fun1/illustrations/pete', include: 26, lks: 'e Pete /&emacr;/', keyword: 'pete' },
          { soundFile: 'a.mp3', cardId: 205, label: 'Ee', color: 'red-card', vowel: 1, col: 11, row: 1, image: '/Fun2/illustrations/me', include: 52, lks: 'e me /&emacr;/', keyword: 'me' },
          { soundFile: 'a.mp3', cardId: 6, label: 'Ff', color: 'yellow-card', vowel: 0, col: 12, row: 1, image: '/Fun1/illustrations/Fun', include: 1, lks: 'f fun /f/', keyword: 'fun' },
          { soundFile: 'a.mp3', cardId: 90, label: '&#601;', color: 'red-card', vowel: 1, col: 13, row: 1, image: '/Fun3/illustrations/wagon', include: 42, lks: '&#601; wagon /&#601;/', keyword: 'wagon' },
          { soundFile: 'a.mp3', cardId: 7, label: 'ang', color: 'green-card', vowel: 0, col: 14, row: 1, image: '/Fun1/illustrations/fang', include: 1, lks: 'ang fang /ang/', keyword: 'fang' },
          { soundFile: 'a.mp3', cardId: 8, label: 'ank', color: 'green-card', vowel: 0, col: 15, row: 1, image: '/Fun1/illustrations/bank', include: 1, lks: 'ank bank /ank/', keyword: 'bank' },
          { soundFile: 'a.mp3', cardId: 9, label: 'Gg', color: 'yellow-card', vowel: 0, col: 1, row: 2, image: '/Fun1/illustrations/game', include: 1, lks: 'g game /g/', keyword: 'game' },
          { soundFile: 'a.mp3', cardId: 10, label: 'Hh', color: 'yellow-card', vowel: 0, col: 2, row: 2, image: '/Fun1/illustrations/hat', include: 1, lks: 'h hat /h/', keyword: 'hat' },
          { soundFile: 'a.mp3', cardId: 11, label: 'Ii', color: 'red-card', vowel: 1, col: 3, row: 2, image: '/Fun1/illustrations/itch', include: 1, lks: 'i itch /&#301;/', keyword: 'itch' },
          { soundFile: 'a.mp3', cardId: 111, label: 'i-e', color: 'red-card', vowel: 1, col: 4, row: 2, image: '/Fun1/illustrations/pine', include: 26, lks: 'i pine /&imacr;/', keyword: 'pine' },
          { soundFile: 'a.mp3', cardId: 211, label: 'Ii', color: 'red-card', vowel: 1, col: 5, row: 2, image: '/Fun2/illustrations/hi', include: 52, lks: 'i hi /&imacr;/', keyword: 'hi' },
          { soundFile: 'a.mp3', cardId: 401, label: 'Ii', color: 'red-card', vowel: 1, col: 7, row: 2, image: '/Fun3/illustrations/animal', include: 61, lks: 'i animal /&ubreve;/ or /&#301;/', keyword: 'animal' },
          { soundFile: 'a.mp3', cardId: 311, label: 'Ii', color: 'red-card', vowel: 1, col: 6, row: 2, image: '/Fun3/illustrations/champion', include: 63, lks: 'i champion /&emacr;/', keyword: 'champion' },
          { soundFile: 'a.mp3', cardId: 12, label: 'Jj', color: 'yellow-card', vowel: 0, col: 8, row: 2, image: '/Fun1/illustrations/jug', include: 1, lks: 'j jug /j/', keyword: 'jug' },
          { soundFile: 'a.mp3', cardId: 13, label: 'Kk', color: 'yellow-card', vowel: 0, col: 9, row: 2, image: '/Fun1/illustrations/kite', include: 1, lks: 'k kite /k/', keyword: 'kite' },
          { soundFile: 'a.mp3', cardId: 14, label: 'Ll', color: 'yellow-card', vowel: 0, col: 10, row: 2, image: '/Fun1/illustrations/lamp', include: 1, lks: 'l lamp /l/', keyword: 'lamp' },
          { soundFile: 'a.mp3', cardId: 15, label: 'ing', color: 'green-card', vowel: 0, col: 14, row: 2, image: '/Fun1/illustrations/ring', include: 1, lks: 'ing ring /ing/', keyword: 'ring' },
          { soundFile: 'a.mp3', cardId: 16, label: 'ink', color: 'green-card', vowel: 0, col: 15, row: 2, image: '/Fun1/illustrations/pink', include: 1, lks: 'ink pink /ink/', keyword: 'pink' },
          { soundFile: 'a.mp3', cardId: 17, label: 'Mm', color: 'yellow-card', vowel: 0, col: 1, row: 3, image: '/Fun1/illustrations/man', include: 1, lks: 'm man /m/', keyword: 'man' },
          { soundFile: 'a.mp3', cardId: 18, label: 'Nn', color: 'yellow-card', vowel: 0, col: 2, row: 3, image: '/Fun1/illustrations/nut', include: 1, lks: 'n nut /n/', keyword: 'nut' },
          { soundFile: 'a.mp3', cardId: 19, label: 'Oo', color: 'red-card', vowel: 1, col: 3, row: 3, image: '/Fun1/illustrations/octopus', include: 1, lks: 'o octopus /&#335;/', keyword: 'octopus' },
          { soundFile: 'a.mp3', cardId: 119, label: 'o-e', color: 'red-card', vowel: 1, col: 4, row: 3, image: '/Fun1/illustrations/home', include: 26, lks: 'o home /&omacr;/', keyword: 'home' },
          { soundFile: 'a.mp3', cardId: 219, label: 'Oo', color: 'red-card', vowel: 1, col: 5, row: 3, image: '/Fun2/illustrations/no', include: 52, lks: 'o no /&omacr;/', keyword: 'no' },
          { soundFile: 'a.mp3', cardId: 20, label: 'Pp', color: 'yellow-card', vowel: 0, col: 6, row: 3, image: '/Fun1/illustrations/pan', include: 1, lks: 'p pan /p/', keyword: 'pan' },
          { soundFile: 'a.mp3', cardId: 21, label: 'QUqu', color: 'yellow-card', vowel: 0, col: 7, row: 3, image: '/Fun1/illustrations/queen', include: 1, lks: 'qu queen /kw/', keyword: 'queen' },
          { soundFile: 'a.mp3', cardId: 22, label: 'Rr', color: 'yellow-card', vowel: 0, col: 8, row: 3, image: '/Fun1/illustrations/rat', include: 1, lks: 'r rat /r/', keyword: 'rat' },
          { soundFile: 'a.mp3', cardId: 23, label: 'Ss', color: 'yellow-card', vowel: 0, col: 9, row: 3, image: '/Fun1/illustrations/snake', include: 1, lks: 's snake /s/', keyword: 'snake' },
          { soundFile: 'a.mp3', cardId: 123, label: 'Ss', color: 'yellow-card', vowel: 0, col: 10, row: 3, image: '/Fun1/illustrations/bugs', include: 12, lks: 's bugs /z/', keyword: 'bugs' },
          { soundFile: 'a.mp3', cardId: 24, label: 'ong', color: 'green-card', vowel: 0, col: 14, row: 3, image: '/Fun1/illustrations/song', include: 1, lks: 'ong song /ong/', keyword: 'song' },
          { soundFile: 'a.mp3', cardId: 25, label: 'onk', color: 'green-card', vowel: 0, col: 15, row: 3, image: '/Fun1/illustrations/honk', include: 1, lks: 'onk honk /onk/', keyword: 'honk' },
          { soundFile: 'a.mp3', cardId: 26, label: 'Tt', color: 'yellow-card', vowel: 0, col: 1, row: 4, image: '/Fun1/illustrations/top', include: 1, lks: 't top /t/', keyword: 'top' },
          { soundFile: 'a.mp3', cardId: 27, label: 'Uu', color: 'red-card', vowel: 1, col: 2, row: 4, image: '/Fun1/illustrations/up', include: 1, lks: 'u up /&ubreve;/', keyword: 'up' },
          { soundFile: 'a.mp3', cardId: 127, label: 'u-e', color: 'red-card', vowel: 1, col: 3, row: 4, image: '/Fun1/illustrations/mule', include: 26, lks: 'u mule /&umacr;/', keyword: 'mule' },
          { soundFile: 'a.mp3', cardId: 227, label: 'u-e', color: 'red-card', vowel: 1, col: 4, row: 4, image: '/Fun1/illustrations/rule', include: 26, lks: 'u rule /&uuml;/', keyword: 'rule' },
          { soundFile: 'a.mp3', cardId: 327, label: 'Uu', color: 'red-card', vowel: 1, col: 5, row: 4, image: '/Fun2/illustrations/student', include: 52, lks: 'u pupil /&umacr;/', keyword: 'pupil' },
          { soundFile: 'a.mp3', cardId: 427, label: 'Uu', color: 'red-card', vowel: 1, col: 6, row: 4, image: '/Fun2/illustrations/flu', include: 52, lks: 'u flu /&uuml;/', keyword: 'flu' },
          { soundFile: 'a.mp3', cardId: 28, label: 'Vv', color: 'yellow-card', vowel: 0, col: 7, row: 4, image: '/Fun1/illustrations/van', include: 1, lks: 'v van /v/', keyword: 'van' },
          { soundFile: 'a.mp3', cardId: 29, label: 'Ww', color: 'yellow-card', vowel: 0, col: 8, row: 4, image: '/Fun1/illustrations/wind', include: 1, lks: 'w wind /w/', keyword: 'wind' },
          { soundFile: 'a.mp3', cardId: 30, label: 'Xx', color: 'yellow-card', vowel: 0, col: 9, row: 4, image: '/Fun1/illustrations/fox', include: 1, lks: 'x fox /ks/', keyword: 'fox' },
          { soundFile: 'a.mp3', cardId: 31, label: 'Yy', color: 'yellow-card', vowel: 0, col: 10, row: 4, image: '/Fun1/illustrations/yellow', include: 1, lks: 'y yellow /y/', keyword: 'yellow' },
          { soundFile: 'a.mp3', cardId: 32, label: 'Zz', color: 'yellow-card', vowel: 0, col: 11, row: 4, image: '/Fun1/illustrations/zebra', include: 1, lks: 'z zebra /z/', keyword: 'zebra' },
          { soundFile: 'a.mp3', cardId: 33, label: 'ung', color: 'green-card', vowel: 0, col: 14, row: 4, image: '/Fun1/illustrations/lung', include: 1, lks: 'ung lung /ung/', keyword: 'lung' },
          { soundFile: 'a.mp3', cardId: 34, label: 'unk', color: 'green-card', vowel: 0, col: 15, row: 4, image: '/Fun1/illustrations/junk', include: 1, lks: 'unk junk /unk/', keyword: 'junk' },
          { soundFile: 'a.mp3', cardId: 35, label: 'wh', color: 'yellow-card', vowel: 0, col: 1, row: 5, image: '/Fun1/illustrations/whistle', include: 1, lks: 'wh whistle /w/', keyword: 'whistle' },
          { soundFile: 'a.mp3', cardId: 36, label: 'ch', color: 'yellow-card', vowel: 0, col: 2, row: 5, image: '/Fun1/illustrations/chin', include: 1, lks: 'ch chin /ch/', keyword: 'chin' },
          { soundFile: 'a.mp3', cardId: 83, label: 'ch', color: 'yellow-card', vowel: 0, col: 3, row: 5, image: '/Fun3/illustrations/chorus', include: 151, lks: 'ch chorus /k/', keyword: 'chorus' },
          { soundFile: 'a.mp3', cardId: 37, label: 'sh', color: 'yellow-card', vowel: 0, col: 4, row: 5, image: '/Fun1/illustrations/ship', include: 1, lks: 'sh ship /sh/', keyword: 'ship' },
          { soundFile: 'a.mp3', cardId: 38, label: 'th', color: 'yellow-card', vowel: 0, col: 5, row: 5, image: '/Fun1/illustrations/thumb', include: 1, lks: 'th thumb /th/', keyword: 'thumb' },
          { soundFile: 'a.mp3', cardId: 84, label: 'ph', color: 'yellow-card', vowel: 0, col: 6, row: 5, image: '/Fun3/illustrations/phone', include: 151, lks: 'ph phone /f/', keyword: 'phone' },
          { soundFile: 'a.mp3', cardId: 39, label: 'ck', color: 'yellow-card', vowel: 0, col: 7, row: 5, image: '/Fun1/illustrations/sock', include: 1, lks: 'ck sock /k/', keyword: 'sock' },
          { soundFile: 'a.mp3', cardId: 91, label: 'tch', color: 'yellow-card', vowel: 0, col: 8, row: 5, image: '/Fun3/illustrations/catch', include: 2, lks: 'tch catch /ch/', keyword: 'catch' },
          { soundFile: 'a.mp3', cardId: 89, label: 'dge', color: 'yellow-card', vowel: 0, col: 9, row: 5, image: '/Fun3/illustrations/fudge', include: 141, lks: 'dge fudge /j/', keyword: 'fudge' },
          { soundFile: 'a.mp3', cardId: 40, label: 'Yy', color: 'red-card', vowel: 0, col: 10, row: 5, image: '/Fun2/illustrations/babycry', include: 52, lks: 'y cry /&imacr;/<br>y baby /&emacr;/', keyword: 'baby' },
          { soundFile: 'a.mp3', cardId: 43, label: 'all', color: 'green-card', vowel: 0, col: 0, row: 5, image: '/Fun1/illustrations/ball', include: 1, lks: 'all ball /&#559;l/', keyword: 'ball' },
          { soundFile: 'a.mp3', cardId: 44, label: 'am', color: 'green-card', vowel: 0, col: 0, row: 5, image: '/Fun1/illustrations/ham', include: 1, lks: 'am ham /am/', keyword: 'ham' },
          { soundFile: 'a.mp3', cardId: 45, label: 'an', color: 'green-card', vowel: 0, col: 0, row: 5, image: '/Fun1/illustrations/fan', include: 1, lks: 'an fan /an/', keyword: 'fan' },
          { soundFile: 'a.mp3', cardId: 41, label: 'ai', color: 'red-card', vowel: 0, col: 1, row: 8, image: '/Fun2/illustrations/bait', include: 117, lks: 'ai bait /&amacr;/', keyword: 'bait' },
          { soundFile: 'a.mp3', cardId: 42, label: 'ay', color: 'red-card', vowel: 0, col: 2, row: 8, image: '/Fun2/illustrations/play', include: 117, lks: 'ay play /&amacr;/', keyword: 'play' },
          { soundFile: 'a.mp3', cardId: 76, label: 'eigh', color: 'red-card', vowel: 0, col: 3, row: 8, image: '/Fun3/illustrations/eight', include: 118, lks: 'eigh eight /&amacr;/', keyword: 'eight' },
          { soundFile: 'a.mp3', cardId: 77, label: 'ei', color: 'red-card', vowel: 0, col: 4, row: 8, image: '/Fun3/illustrations/vein', include: 118, lks: 'ei vein /&amacr;/', keyword: 'vein' },
          { soundFile: 'a.mp3', cardId: 178, label: 'ea', color: 'red-card', vowel: 0, col: 5, row: 8, image: '/Fun3/illustrations/steak', include: 118, lks: 'ea steak /&amacr;/', keyword: 'steak' },
          { soundFile: 'a.mp3', cardId: 46, label: 'ee', color: 'red-card', vowel: 0, col: 5, row: 8, image: '/Fun2/illustrations/jeep', include: 117, lks: 'ee jeep /&emacr;/', keyword: 'jeep' },
          { soundFile: 'a.mp3', cardId: 48, label: 'ey', color: 'red-card', vowel: 0, col: 7, row: 8, image: '/Fun2/illustrations/key', include: 117, lks: 'ey key /&emacr;/', keyword: 'key' },
          { soundFile: 'a.mp3', cardId: 79, label: 'ie', color: 'red-card', vowel: 0, col: 8, row: 8, image: '/Fun3/illustrations/piece', include: 121, lks: 'ie piece /&emacr;/', keyword: 'piece' },
          { soundFile: 'a.mp3', cardId: 177, label: 'ei', color: 'red-card', vowel: 0, col: 9, row: 8, image: '/Fun3/illustrations/ceiling', include: 121, lks: 'ei ceiling /&emacr;/', keyword: 'ceiling' },
          { soundFile: 'a.mp3', cardId: 78, label: 'ea', color: 'red-card', vowel: 0, col: 10, row: 8, image: '/Fun2/illustrations/eat', include: 117, lks: 'ea eat /&emacr;/', keyword: 'eat' },
          { soundFile: 'a.mp3', cardId: 278, label: 'ea', color: 'red-card', vowel: 0, col: 11, row: 8, image: '/Fun3/illustrations/bread', include: 123, lks: 'ea bread /&#277;/', keyword: 'bread' },
          { soundFile: 'a.mp3', cardId: 80, label: 'igh', color: 'red-card', vowel: 0, col: 12, row: 8, image: '/Fun3/illustrations/light', include: 127, lks: 'igh light /&imacr;/', keyword: 'light' },
          { soundFile: 'a.mp3', cardId: 62, label: 'oa', color: 'red-card', vowel: 0, col: 1, row: 9, image: '/Fun2/illustrations/boat', include: 117, lks: 'oa boat /&omacr;/', keyword: 'boat' },
          { soundFile: 'a.mp3', cardId: 63, label: 'oe', color: 'red-card', vowel: 0, col: 2, row: 9, image: '/Fun2/illustrations/toe', include: 117, lks: 'oe toe /&omacr;/', keyword: 'toe' },
          { soundFile: 'a.mp3', cardId: 64, label: 'ow', color: 'red-card', vowel: 0, col: 3, row: 9, image: '/Fun2/illustrations/snowplow', include: 117, lks: 'ow snow /&omacr;/<br>ow plow /ou/', keyword: 'snow' },
          { soundFile: 'a.mp3', cardId: 65, label: 'ou', color: 'red-card', vowel: 0, col: 4, row: 9, image: '/Fun2/illustrations/troutsoup', include: 117, lks: 'ou trout /ou/<br>ou soup /&uuml;/', keyword: 'trout soup' },
          { soundFile: 'a.mp3', cardId: 92, label: 'ui', color: 'red-card', vowel: 0, col: 5, row: 9, image: '/Fun3/illustrations/suit', include: 127, lks: 'ui suit /&uuml;/', keyword: 'suit' },
          { soundFile: 'a.mp3', cardId: 67, label: 'ue', color: 'red-card', vowel: 0, col: 6, row: 9, image: '/Fun2/illustrations/ue_bluerescue', include: 117, lks: 'ue blue /&uuml;/<br>ue rescue /&umacr;/', keyword: 'blue rescue' },
          { soundFile: 'a.mp3', cardId: 68, label: 'ew', color: 'red-card', vowel: 0, col: 7, row: 9, image: '/Fun2/illustrations/chew', include: 117, lks: 'ew chew /&uuml;/', keyword: 'chew' },
          { soundFile: 'a.mp3', cardId: 66, label: 'oo', color: 'red-card', vowel: 0, col: 8, row: 9, image: '/Fun2/illustrations/oo_schoolbook', include: 117, lks: 'oo school /&uuml;/<br>oo book /&#367;/', keyword: 'school book' },
          { soundFile: 'a.mp3', cardId: 81, label: 'oo', color: 'red-card', vowel: 0, col: 9, row: 9, image: '/Fun3/illustrations/book', include: 116, lks: 'oo school /&uuml;/<br>oo book /&#367;/', keyword: 'school book' },
          { soundFile: 'a.mp3', cardId: 55, label: 'oi', color: 'red-card', vowel: 0, col: 10, row: 9, image: '/Fun2/illustrations/coin', include: 117, lks: 'oi coin /oi/', keyword: 'coin' },
          { soundFile: 'a.mp3', cardId: 56, label: 'oy', color: 'red-card', vowel: 0, col: 11, row: 9, image: '/Fun2/illustrations/boy', include: 117, lks: 'oy boy /oi/', keyword: 'boy' },
          { soundFile: 'a.mp3', cardId: 69, label: 'au', color: 'red-card', vowel: 0, col: 12, row: 9, image: '/Fun2/illustrations/august', include: 117, lks: 'au August /&#559;/', keyword: 'August' },
          { soundFile: 'a.mp3', cardId: 70, label: 'aw', color: 'red-card', vowel: 0, col: 13, row: 9, image: '/Fun2/illustrations/saw', include: 117, lks: 'aw saw /&#559;/', keyword: 'saw' },
          { soundFile: 'a.mp3', cardId: 49, label: 'ind', color: 'green-card', vowel: 0, col: 2, row: 6, image: '/Fun2/illustrations/find', include: 1, lks: 'ind find /&imacr;nd/', keyword: 'find' },
          { soundFile: 'a.mp3', cardId: 50, label: 'ild', color: 'green-card', vowel: 0, col: 1, row: 6, image: '/Fun2/illustrations/wild', include: 1, lks: 'ild wild /&imacr;ld/', keyword: 'wild' },
          { soundFile: 'a.mp3', cardId: 51, label: 'old', color: 'green-card', vowel: 0, col: 3, row: 6, image: '/Fun2/illustrations/cold', include: 1, lks: 'old cold /&omacr;ld/', keyword: 'cold' },
          { soundFile: 'a.mp3', cardId: 52, label: 'olt', color: 'green-card', vowel: 0, col: 4, row: 6, image: '/Fun2/illustrations/colt', include: 1, lks: 'olt colt /&omacr;lt/', keyword: 'colt' },
          { soundFile: 'a.mp3', cardId: 53, label: 'ost', color: 'green-card', vowel: 0, col: 5, row: 6, image: '/Fun2/illustrations/post', include: 1, lks: 'ost post /&omacr;st/', keyword: 'post' },
          { soundFile: 'a.mp3', cardId: 54, label: 'ive', color: 'green-card', vowel: 0, col: 6, row: 6, image: '/Fun2/illustrations/give', include: 31, lks: 'ive give /&#301;v/', keyword: 'give' },
          { soundFile: 'a.mp3', cardId: 71, label: 'stle', color: 'green-card', vowel: 0, col: 7, row: 6, image: '/Fun3/illustrations/castle', include: 86, lks: 'stle castle /sl/', keyword: 'castle' },
          { soundFile: 'a.mp3', cardId: 72, label: 'tion', color: 'green-card', vowel: 0, col: 8, row: 6, image: '/Fun3/illustrations/vacation', include: 97, lks: 'tion vacation /sh&ubreve;n/', keyword: 'vacation' },
          { soundFile: 'a.mp3', cardId: 73, label: 'sion', color: 'green-card', vowel: 0, col: 9, row: 6, image: '/Fun3/illustrations/mansion', include: 97, lks: 'sion mansion /sh&ubreve;n/', keyword: 'mansion' },
          { soundFile: 'a.mp3', cardId: 173, label: 'sion', color: 'green-card', vowel: 0, col: 10, row: 6, image: '/Fun3/illustrations/television', include: 97, lks: 'sion television /zh&ubreve;n/', keyword: 'television' },
          { soundFile: 'a.mp3', cardId: 87, label: 'ture', color: 'green-card', vowel: 0, col: 11, row: 6, image: '/Fun3/illustrations/capture', include: 161, lks: 'ture capture /ch&#601;r/', keyword: 'capture' },
          { soundFile: 'a.mp3', cardId: 88, label: 'tu', color: 'green-card', vowel: 0, col: 12, row: 6, image: '/Fun3/illustrations/spatula', include: 161, lks: 'tu spatula /ch&uuml;/', keyword: 'spatula' },
          { soundFile: 'a.mp3', cardId: 86, label: 'ti', color: 'green-card', vowel: 0, col: 13, row: 6, image: '/Fun3/illustrations/patient', include: 161, lks: 'ti patient /sh/', keyword: 'patient' },
          { soundFile: 'a.mp3', cardId: 82, label: 'ci', color: 'green-card', vowel: 0, col: 14, row: 6, image: '/Fun3/illustrations/glacier', include: 161, lks: 'ci glacier /sh/', keyword: 'glacier' },
          { soundFile: 'a.mp3', cardId: 57, label: 'ar', color: 'red-card', vowel: 0, col: 1, row: 7, image: '/Fun2/illustrations/car', include: 102, lks: 'ar car /ar/', keyword: 'car' },
          { soundFile: 'a.mp3', cardId: 58, label: 'or', color: 'red-card', vowel: 0, col: 4, row: 7, image: '/Fun2/illustrations/horn', include: 102, lks: 'or horn /or/', keyword: 'horn' },
          { soundFile: 'a.mp3', cardId: 59, label: 'er', color: 'red-card', vowel: 0, col: 7, row: 7, image: '/Fun2/illustrations/her', include: 102, lks: 'er her /&#601;r/', keyword: 'her' },
          { soundFile: 'a.mp3', cardId: 60, label: 'ir', color: 'red-card', vowel: 0, col: 8, row: 7, image: '/Fun2/illustrations/bird', include: 102, lks: 'ir bird /&#601;r/', keyword: 'bird' },
          { soundFile: 'a.mp3', cardId: 61, label: 'ur', color: 'red-card', vowel: 0, col: 9, row: 7, image: '/Fun2/illustrations/burn', include: 102, lks: 'ur burn /&#601;r/', keyword: 'burn' },
          { soundFile: 'a.mp3', cardId: 74, label: 'ar', color: 'red-card', vowel: 0, col: 2, row: 7, image: '/Fun3/illustrations/beggar', include: 102, lks: 'ar beggar /&#601;r/', keyword: 'beggar' },
          { soundFile: 'a.mp3', cardId: 75, label: 'or', color: 'red-card', vowel: 0, col: 5, row: 7, image: '/Fun3/illustrations/doctor', include: 102, lks: 'or doctor /&#601;r/', keyword: 'doctor' },
          { soundFile: 'a.mp3', cardId: 174, label: 'ar', color: 'red-card', vowel: 0, col: 3, row: 7, image: '/Fun3/illustrations/warm', include: 101, lks: 'ar warm /or/', keyword: 'warm' },
          { soundFile: 'a.mp3', cardId: 175, label: 'or', color: 'red-card', vowel: 0, col: 6, row: 7, image: '/Fun3/illustrations/worm', include: 101, lks: 'or worm /&#601;r/', keyword: 'worm' },
          { soundFile: 'a.mp3', cardId: 500, label: 'wr', color: 'yellow-card', vowel: 0, col: 1, row: 10, image: '/Fun3/silent-letters/wrist', include: 151, lks: 'wr wrist /r/', keyword: 'wrist' },
          { soundFile: 'a.mp3', cardId: 501, label: 'rh', color: 'yellow-card', vowel: 0, col: 2, row: 10, image: '/Fun3/silent-letters/rhyme', include: 151, lks: 'rh rhyme /r/', keyword: 'rhyme' },
          { soundFile: 'a.mp3', cardId: 502, label: 'gn', color: 'yellow-card', vowel: 0, col: 3, row: 10, image: '/Fun3/silent-letters/gnat', include: 151, lks: 'gn gnat /n/', keyword: 'gnat' },
          { soundFile: 'a.mp3', cardId: 503, label: 'kn', color: 'yellow-card', vowel: 0, col: 4, row: 10, image: '/Fun3/silent-letters/knife', include: 151, lks: 'kn knife /n/', keyword: 'knife' },
          { soundFile: 'a.mp3', cardId: 504, label: 'mn', color: 'yellow-card', vowel: 0, col: 5, row: 10, image: '/Fun3/silent-letters/column', include: 151, lks: 'mn column /m/', keyword: 'column' },
          { soundFile: 'a.mp3', cardId: 505, label: 'mb', color: 'yellow-card', vowel: 0, col: 6, row: 10, image: '/Fun3/silent-letters/lamb', include: 151, lks: 'mb lamb /m/', keyword: 'lamb' },
          { soundFile: 'a.mp3', cardId: 506, label: 'gh', color: 'yellow-card', vowel: 0, col: 7, row: 10, image: '/Fun3/silent-letters/ghost', include: 151, lks: 'gh ghost /g/', keyword: 'ghost' },
        ],
      },
    ],
  },
};
