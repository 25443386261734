<template>
  <div id="positioner">
      <h1>Feedback</h1>
<iframe height="684" title="Embedded Wufoo Form" style="width:100%;border:none" :src="wufooLink"> <a :href="wufooLink">Fill out my Wufoo form!</a> </iframe>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Feedback',
  computed: {
    ...mapState('authentication', ['idTokenPayload']),
  },
  data() {
    const wufooLink = '';
    return { wufooLink };
  },

  beforeRouteEnter(to, from, next) {

    next((vm) => {
      const fromPage = from?.name ?? '';
      let user = '';
      if (vm.idTokenPayload) {
        user = JSON.parse(vm.idTokenPayload)['https://interactivities.wilsonacademy.com/contactId'];
      }
      vm.wufooLink = `https://wilsonlanguagetraining.wufoo.com/embed/p10ad6tn07crtpo/def/field112=Interactivities&field114=${fromPage}&field116=${user}&field113=`;
    });
  },
};
</script>

<style lang="scss" scoped>
    h1{
        color: #0099dc;
    }
    .flex-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    }
    #app{
      #positioner{
        padding-top: 100px;
        overflow-y: auto;
        display: block;
        height: calc( 100% - 100px + 5rem);
      }
    }
</style>
