<style scoped lang="scss">

.trickwords-carousel {
  .word,
  .label,
  .counter {
    color: $trickwords-magenta;
  }
}
</style>
<template>
  <div id="positioner">
    <ActivityHeader activity-type="<span class='twoline-title'><span>Drill Sounds</span><br><span>/Warm-Up</span></span>"
    activity-name="Trick Words"
    :back-button-title="backButtonTitle"
    back-button-route="choosedrillsounds" />

    <div id="carousel-wrapper" class="trickwords-carousel">
      <div id="help-text-wrapper">
        <span id="help-text">Select trick words flashcards to begin</span>
      </div>
      <carousel-3d ref="carousel" :controls-visible="true" :count="wordList.length" :space="100" :perspective="0" :clickable="false">
        <slide v-for="(word, i) in wordList" :index="i" :key="i">
          <div class="box">
            <div class="word" data-automationId="trick_words_selected">{{ word }}</div>
            <span class="label">
              Fundations
              <span class="tw-fun-level">{{ levelLabel }}</span>
            </span>
            <span class="counter">{{ i + 1 }} of {{ wordList.length }}</span>
          </div>
        </slide>
      </carousel-3d>
    </div>
    <template v-if="false">
      <div id="carousel-cardtype-controls">
        <p v-html="title"></p>
        <button v-if="weekId !== '0' && !hideToggleButton" @click="toggleDisplay">{{ buttonlabel }}</button>
        <div v-if="weekId !== '0' && days.length > 1 && showCurrent">
          <button :class="{ active: currentDay == i }" v-for="(day, i) in days" :key="day" @click="switchDay(i)">Day: {{ day }}</button>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="unit-buttons">
        <button class="button plain-button hide-words" :class="{showWords: !unitWordsVisible}" @click="toggleUnitWordVisibility()">{{toggleTitle}}</button>
        <button class="button button-sm clear-words" type="button" @click="clearAllWords()" v-if="unitWordsVisible" data-automationId="trick_words_clear_words_button">Clear Words</button>
      </div>
      <div id="controls" v-if="unitWordsVisible">

        <button class="unit-bav-button" v-if="wordsInUnit.length > 4" @click="decrementUnitsAvailable()">&lt; Prev unit</button>
        <div class="unit" v-for="units in wordsInUnit.slice(unitPage * 4, (unitPage * 4) + 4)" :key="units.unit">
          <div class="unit-label"><label><input type="checkbox" :value="units.unit" v-model="unitsSelected" @change="changeUnit($event, units.unit)"> Unit {{ units.unit }}</label></div>
          <div class="words">
            <div class="word" v-for="word in units.words" :key="word" data-automationId="trick_words_checkbox"><label><input type="checkbox" :value="word" @click="changeWord()" v-model="wordList"> {{ word }}</label></div>
          </div>
        </div>
        <button class="unit-bav-button" v-if="wordsInUnit.length > 4" @click="incrementUnitsAvailable()">Next unit &gt;</button>
      </div>
    </template>

    <PrevNextButtons v-if="!isFunk_1" activity="TW"></PrevNextButtons>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { Carousel3d, Slide } from 'vue-carousel-3d';
import ActivityHeader from '@/components/ActivityHeader.vue';
import PrevNextButtons from '@/components/PrevNextButtons.vue';

export default {
  name: 'TrickWords',
  components: { ActivityHeader, PrevNextButtons, Carousel3d, Slide },
  data() {
    return {
      days: [],
      wordList: [],
      currentDay: 0,
      currentWeekWords: [],
      cumulativeWords: [],
      showCurrent: true,
      hideToggleButton: false,
      backButtonTitle: '',
      wordsInUnit: [],
      unitsSelected: [],
      unitWordsVisible: true,
      toggleTitle: 'Hide Units',
      showToggleButton: true,
      totalUnitPages: 0,
      unitPage: 0,
    };
  },
  props: {
    programId: { type: String },
    unitId: { type: String },
    weekId: { type: String },
    dayId: { type: String },
  },
  created() {
    this.getDataForDisplay();
  },
  mounted() {
    this.backButtonTitle = this.weekId === '0' ? 'Back' : 'Drill Sounds';
    // this.determineInitialUnitsVisible();
    this.showCurrentButton();
    if (this.programId === 'fun2') {
      this.preSelectUnit(this.unitId);
    }
  },
  computed: {
    ...mapState('trickWords', ['trickWords']),
    ...mapGetters('dataStore', ['weekNumber']),
    buttonlabel() {
      return this.showCurrent ? 'Show All' : 'Show Introduced';
    },
    isFunk_1() {
      return this.programId === 'funk' || this.programId === 'fun1';
    },
    levelLabel() {
      let label = '';
      switch (this.programId) {
        case 'funk':
          label = 'Level K';
          break;
        case 'fun1':
          label = 'Level 1';
          break;
        case 'fun2':
          label = 'Level 2';
          break;
        default:
          label = 'Level';
      }
      return label;
    },

    title() {
      if (this.weekId !== '0' && this.currentWeekWords[this.currentDay] != null) {
        return this.showCurrent ? `Showing <strong>Introduced</strong> Words For Unit ${this.unitId}, Week ${this.weekId}` : `<strong>Showing All</strong> Trick Words Up To Unit ${this.unitId}, Week ${this.weekId}`;
      }
      return `<strong>Showing All</strong> Trick Words Up To Unit ${this.unitId}`;
    },
  },
  methods: {
    showCurrentButton() {
      if (this.currentWeekWords[this.currentDay] !== null) {
        this.showToggleButton = false;
      }
    },
    toggleUnitWordVisibility() {
      this.unitWordsVisible = !this.unitWordsVisible;
      if (this.unitWordsVisible === true) {
        this.toggleTitle = 'Hide Units';
      } else {
        this.toggleTitle = 'Show Units';
      }
    },
    incrementUnitsAvailable() {
      this.unitPage += 1;
      if (this.unitPage > this.totalUnitPages - 1) {
        this.unitPage = 0;
      }
    },
    decrementUnitsAvailable() {
      this.unitPage -= 1;
      if (this.unitPage < 0) {
        this.unitPage = this.totalUnitPages - 1;
      }
    },
    toggleDisplay() {
      this.showCurrent = !this.showCurrent;
      if (this.showCurrent) {
        this.wordList = this.currentWeekWords[this.currentDay].words;
      } else {
        this.wordList = this.cumulativeWords;
      }
    },
    switchDay(day) {
      this.currentDay = day;
      this.wordList = this.currentWeekWords[this.currentDay].words;
    },
    changeUnit(evt, unit) {
      const wordsToChange = this.wordsInUnit.find((w) => w.unit === unit);
      this.wordList = this.removeWordsFromDisplay(wordsToChange.words);

      if (evt.target.checked) {
        this.wordList = this.wordList.concat(wordsToChange.words);
      }
      this.$refs.carousel.goSlide(this.$refs.carousel.currentIndex);

    },
    preSelectUnit(unit) {
      const unitNum = parseInt(unit, 10);
      const wordsToChange = this.wordsInUnit.find((w) => w.unit === unitNum);
      this.wordList = this.wordList.concat(wordsToChange.words);
      this.unitsSelected.push(unit);
      if (this.wordsInUnit.length < 5) {
        this.unitPage = 0;
      } else {
        this.unitPage = Math.ceil(unitNum / 4) - 1;
      }
      this.$refs.carousel.goSlide(this.$refs.carousel.currentIndex);
    },
    changeWord() {
      this.$refs.carousel.goSlide(this.$refs.carousel.currentIndex);
    },

    removeWordsFromDisplay(words) {
      return this.wordList.filter((word) => !words.includes(word));
    },

    clearAllWords() {
      this.wordList = [];
      this.unitsSelected = [];
    },

    getDataForDisplay() {
      // if (this.isFunk_1) {
      const trickWordsForProgram = this.trickWords.find((prog) => prog.program === this.programId);
      this.wordsInUnit = trickWordsForProgram.words;
      if (this.programId === 'fun2') {
        const unitNum = parseInt(this.unitId, 10);
        this.wordsInUnit = this.wordsInUnit.filter((w) => w.unit <= unitNum);
      }
      this.totalUnitPages = Math.ceil(this.wordsInUnit.length / 4);
      // } else {
      //   const trickWordsForProgram = this.trickWords.find((c) => c.program === this.programId);
      //   const weekNumber = this.weekNumber(this.programId, this.unitId, this.weekId);
      //   this.currentWeekWords = trickWordsForProgram.words.filter((w) => w.weekXref === weekNumber);
      //   const prevFiveUnits = Math.max(Number(this.unitId) - 5, 1);
      //   const prevFiveWeekNumber = this.weekNumber(this.programId, prevFiveUnits, '1');
      //   if (this.weekId !== '0') {
      //     this.cumulativeWords = trickWordsForProgram.words
      //       .filter((w) => w.weekXref >= prevFiveWeekNumber && w.weekXref <= weekNumber)
      //       .flatMap((w) => w.words);
      //   } else {
      //     this.cumulativeWords = trickWordsForProgram.words
      //       .filter((w) => w.weekXref <= weekNumber)
      //       .flatMap((w) => w.words);
      //   }

      //   this.days = this.currentWeekWords.map((w) => w.days);
      //   if (this.showCurrent && this.weekId !== '0') {
      //     if (this.currentWeekWords.length > 0) {
      //       this.wordList = this.currentWeekWords[this.currentDay].words;
      //     } else {
      //       this.wordList = this.cumulativeWords;
      //       this.showCurrent = false;
      //       this.hideToggleButton = true;
      //     }
      //   } else {
      //     this.wordList = this.cumulativeWords;
      //   }
      // }
    },
  },
};
</script>
<style scoped lang="scss">
#carousel-wrapper{
  display: flex;
  #help-text-wrapper {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-content: center;
    justify-items: center;
    #help-text{
      flex: 1 0 auto;
      text-align: center;
      @extend %headings-shared;
      margin: auto;
      font-size: 2vh;
      color: gray;
    }
  }
}
  #carousel-cardtype-controls{
    flex-direction: column;
    p {
      font-size: 2rem;
      margin-bottom: 2rem;
    }
  }
  #controls {
    display: flex;
    justify-content: center;
    font-family: wManuscript;
    margin: 0;
    width: 100%;
    box-sizing: border-box;
    .unit-nav-button{
      flex: 1 0 8%;
    }
    .unit {
      flex: 0 0 21%;
      background: whitesmoke;
      margin: 0 .5%;
      box-sizing: border-box;
      padding: 10px;

      .unit-label {
        @extend %headings-shared;
        font-size: 2.2rem;
        margin-bottom: 1.5rem;
      }
      .words{
        display: flex;
        flex-wrap: wrap;
        .word {
          font-size: 1.4rem;
          cursor: pointer;
          flex: 0 0 50%;
          @media(max-width: 1200px), (max-height: 800px){
            flex: 0 0 33%;
          }

          label{
            padding-bottom: 8px;
            display: block;
            margin: 1px 0;
          }
        }
      }
      @media(max-width: 1000px), (max-height: 700px){
        .unit-label{
          font-size: 1.5rem;
          margin-bottom: .5rem;
        }
        .words {
          .word {
            flex: 1 0 auto;
          }
        }
      }
      &.shown{
        display: block;
      }
    }
  }

  .unit-buttons{
    display: flex;
    position: relative;
    button {
      &.hide-words{
        align-self: center;
        justify-self: center;
        margin: auto;
        position: relative;
        padding-right: 25px;
        &:before {
          content: '';
          background-image: url(/images/Fun1/illustrations/up.svg);
          background-repeat: no-repeat;
          background-size: contain;
          padding-right: 20px;
          position: absolute;
          right: 0;
          height: 29px;
          transform: rotateZ(20deg) scaleY(-1);
          top: 5px;

        }
        &.showWords {
          &:before{
            transform: rotateZ(-10deg);
          }
        }
      }
      &.clear-words{
        position: absolute;
        right: 0;
      }
    }
  }
</style>
