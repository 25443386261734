<template>
  <div>
    Loading.
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import router from '@/router';
import mixpanel from 'mixpanel-browser';

export default {
  name: 'Callback',

  computed: {
    ...mapGetters('authentication', ['redirect_url']),
  },

  created() {
    const that = this;
    this.handleAuthentication()
      .then(() => {
        const redirectTo = { name: '', params: '' };
        if (that === undefined || this.redirect_url === null || that.redirect_url === undefined) {
          redirectTo.name = 'chooseprogram';
        } else if (that.redirect_url.name === '' || that.redirect_url.name === null) {
          redirectTo.name = 'chooseprogram';
          redirectTo.params = '';
        } else {
          redirectTo.name = that.redirect_url.name;
          redirectTo.params = that.redirect_url.params;
        }
        this.clearRedirect();

        if (redirectTo.params.programId) {
          this.setCurrentProgram({ program: redirectTo.params.programId });
        }
        router.push({ name: redirectTo.name, params: redirectTo.params });

        //Register mixpanel users who login via universal login page
        const tokenJson = sessionStorage.getItem('idToken_payload');
        if (tokenJson){
          const token = JSON.parse(tokenJson);
          const user = {
            id: token['https://wilsonacademy.com/contactId'],
            email: token['https://wilsonacademy.com/email'],
            name: token['https://interactivities.wilsonacademy.com/name'],
            roles: token['https://wilsonacademy.com/roles'],
          };
          if (user.email?.length) {
            mixpanel.identify(user.email);
            mixpanel.people.set({
              $name: user.name,
              $email: user.email,
              $distinct_id: user.email,
              roles: user.roles,
              'Wilson ID': user.id,
            });
            if (user.email === 'uitester@wilsonlanguage.com') {
              mixpanel.opt_out_tracking();
            }
          }
        }
        // end mixpanel register
        mixpanel.track('Login');
      })
      .catch((err) => {
        if (err.message === 'UNAUTHORIZED') {
          console.error('UNAUTHORIZED');
          this.logout();
          router.push({ name: 'Unauthorized' });
        }
      });
  },

  methods: {
    ...mapActions('authentication', {
      handleAuthentication: 'handleAuthentication',
      clearRedirect: 'clearRedirect',
      logout: 'logout',
    }),
    ...mapActions('appState', ['setCurrentProgram']),
  },
};
</script>
